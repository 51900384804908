export const SAVE_CHECK = "SAVE_CHECK";
export const SAVE_TEMPLATE = "SAVE_TEMPLATE";
export const ERROR = "ERROR";
export const LOADING = "LOADING";
export const SAVE_CREDS_IN_SAVE = "SAVE_CREDS_IN_SAVE";
export const STATUS = "STATUS";
export const DELETE_FORM_DATA = "DELETE_FORM_DATA";
export const SAVE_CHECK_ADDRESS = "SAVE_CHECK_ADDRESS";
export const SAVE_CARD_ADDRESS = "SAVE_CARD_ADDRESS";
export const SAVE_CLAIM_HEADER_IDEN = "SAVE_CLAIM_HEADER_IDEN";

export const CHECK_ADDRESS_OPEN = "CHECK_ADDRESS_OPEN";
export const CARD_ADDRESS_OPEN = "CARD_ADDRESS_OPEN";
export const CLAIM_EXTRACTION_OPEN = "CLAIM_EXTRACTION_OPEN";
export const CLAIM_HEADER_IDENTIFICATION_OPEN =
  "CLAIM_HEADER_IDENTIFICATION_OPEN";

export const CLAIM_ADDRESS_OPEN = "CLAIM_ADDRESS_OPEN";
export const CLAIM_HEADER_OPEN = "CLAIM_HEADER_OPEN";
export const CLAIM_FIELDS_OPEN = "CLAIM_FIELDS_OPEN";
export const CHECK_HEADER_OPEN = "CHECK_HEADER_OPEN";
export const CARD_HEADER_OPEN = "CARD_HEADER_OPEN";

export const SAVE_CHECK_HEADER = "SAVE_CHECK_HEADER";
export const SAVE_CARD_HEADER = "SAVE_CARD_HEADER";
export const CHANGE_LOADING_HOME = " CHANGE_LOADING_HOME";

export const SAVE_CLAIM_EXTRAXCTION = "SAVE_CLAIM_EXTRAXCTION";
export const SAVE_CLAIM_ADDRESS = "SAVE_CLAIM_ADDRESS";
export const SAVE_CLAIM_HEADER = "SAVE_CLAIM_HEADER";
export const SAVE_CLAIM_FIELDS = "SAVE_CLAIM_FIELDS";
export const SAVE_LINE_ITEM = "SAVE_LINE_ITEM";
export const SAVE_LINE_FIELD = "SAVE_LINE_FIELD";
export const LINE_ITEM_OPEN = "LINE_ITEM_OPEN";
export const LINE_FIELD_OPEN = "LINE_FIELD_OPEN";
export const LOADING_UPDATE = "LOADING_UPDATE";
export const NO = "NO";
export const SAVE_STATE = "SAVE_STATE";

export const SELECTED_CHECK_ADDRESS = "SELECTED_CHECK_ADDRESS";
export const SELECTED_CARD_ADDRESS = "SELECTED_CARD_ADDRESS";

export const SELECTED_CHECK_HEADER = "SELECTED_CHECK_HEADER";
export const SELECTED_CARD_HEADER = "SELECTED_CARD_HEADER";

export const SELECTED_CLAIM_HEADER = "SELECTED_CLAIM_HEADER";
export const SELECTED_CLAIM_ADDRESS = "SELECTED_CLAIM_ADDRESS";
export const SELECTED_CLAIM_FIELDS = "SELECTED_CLAIM_FIELDS";
export const SELECTED_LINE_FIELDS = "SELECTED_LINE_FIELDS";
export const SELECTED_ALSO_ADD_AS_FIELDS = "SELECTED_ALSO_ADD_AS_FIELDS";

export const SAVING = "SAVING";

export const SAVE_CLAIM = "SAVE_CLAIM";
export const SAVE_LINE = "SAVE_LINE";
export const SAVE_JSON = "SAVE_JSON";
export const SUBMIT_JSON = "SUBMIT_JSON";
export const GET_SAVED_DATA = "GET_SAVED_DATA";
export const SAVE_FROM_API = "SAVE_FROM_API";
export const ADD_EXTRA_BLOCK = "ADD_EXTRA_BLOCK";
export const DELETE_EXTRA_BLOCK = "DELETE_EXTRA_BLOCK";
export const DELETE_DATA = "DELETE_DATA";
export const SUBMIT_TYPE = "SUBMIT_TYPE";
export const PREVIEW_JSON = "PREVIEW_JSON";
export const ADD_FILENAME = "ADD_FILENAME";
export const REMOVE_CHECK_STATUS = "REMOVE_CHECK_STATUS";
