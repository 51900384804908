/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import Dashboard from "./Dashboard";
import Navbar from "../Navbar/Navbar";
import InActiveDashboard from "./InActiveDashboard";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const { TabPane } = Tabs;

const Tab = (props) => {
  const [active, setActive] = useState("1");
  const [totalActiveTemplates, setTotalActiveTemplates] = useState();
  const [activeData, setActiveData] = useState({});
  const [inActiveData, setInActiveData] = useState({});
  const [totalInActiveTemplates, setTotalInActiveTemplates] = useState();

  const styles = makeStyles({
    tooltip: {
      backgroundColor: "#FFFFFF",
      color: "#1890FF",
      border: ".5px solid #999999",
      fontSize: ".85rem",
      fontWeight: "520",
    },
  });
  const changeActive = (e) => {
    setActive(e);
  };
  useEffect(() => {
    if (props?.location?.state) {
      setActive(props?.location?.state);
    }
  }, []);
  const getActiveData = (e, type) => {
    let data = e;

    if (type == "active") {
      setTotalActiveTemplates(data["count"]);
      delete data["count"];
      setActiveData(data);
    } else {
      setTotalInActiveTemplates(data["count"]);
      delete data["count"];
      setInActiveData(data);
    }
  };

  return (
    <div>
      <Navbar component="home" />
      <div style={{ padding: 10 }}>
        <Tabs
          defaultActiveKey={"1"}
          activeKey={active}
          onChange={(e) => changeActive(e)}
        >
          <TabPane
            key="1"
            tab={
              <Tooltip
                classes={{ tooltip: styles().tooltip }}
                title={
                  <div>
                    {Object.keys(activeData)?.map((item) => (
                      <p>
                        {item}-{activeData[item].length}
                      </p>
                    ))}
                  </div>
                }
              >
                <span>
                  Active Templates{" "}
                  {totalActiveTemplates ? `(${totalActiveTemplates})` : null}
                </span>
              </Tooltip>
            }
          >
            <Dashboard getActiveData={getActiveData} />
          </TabPane>
          <TabPane
            key="2"
            tab={
              <Tooltip
                classes={{ tooltip: styles().tooltip }}
                title={
                  <div>
                    {Object.keys(inActiveData)?.map((item) => (
                      <p>
                        {item}-{inActiveData[item].length}
                      </p>
                    ))}
                  </div>
                }
              >
                <span>
                  To Be Configured{" "}
                  {totalInActiveTemplates
                    ? `(${totalInActiveTemplates})`
                    : null}
                </span>
              </Tooltip>
            }
          >
            <InActiveDashboard getActiveData={getActiveData} />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default Tab;
