import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import Logger from "redux-logger";
import formReducer from "./Form/reducer";
import { saveReduer } from "./Save/reducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import environment from "../environment";

const persistConfig = {
  key: "root",
  storage,
};

export const reducers = combineReducers({
  form: formReducer,
  save: saveReduer,
});

let middlewares = [thunk];
if (environment.BASE_URL.includes("localhost")) {
  middlewares = [...middlewares, Logger];
}

export const store = createStore(
  persistReducer(persistConfig, reducers),
  composeWithDevTools(applyMiddleware(...middlewares))
);

export const persistor = persistStore(store);
