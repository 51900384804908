/* eslint-disable */

import * as actionTypes from "./actionTypes";
import axios from "axios";
import _, { sample } from "lodash";
import env from "../../environment";
import keysToRemove from "../../Components/LocalStorageItems";
import { getDropDown, saveCreds } from "../Form/actions";
import LZString from "lz-string";
import { refreshToken, validateApi } from "../../constants";
import { Alert } from "rsuite";
import { ContactSupportOutlined } from "@material-ui/icons";

export const saveCheck = (data) => ({
  type: actionTypes.SAVE_CHECK,
  payload: data,
});

export const saveCheckAddress = (data) => ({
  type: actionTypes.SAVE_CHECK_ADDRESS,
  payload: data,
});
export const saveCardAddress = (data) => ({
  type: actionTypes.SAVE_CARD_ADDRESS,
  payload: data,
});
export const saveClaimAddress = (data) => ({
  type: actionTypes.SAVE_CLAIM_ADDRESS,
  payload: data,
});

export const selectedCheckAdd = (data) => ({
  type: actionTypes.SELECTED_CHECK_ADDRESS,
  payload: data,
});
export const selectedCardAdd = (data) => ({
  type: actionTypes.SELECTED_CARD_ADDRESS,
  payload: data,
});

export const selectedCheckHeader = (data) => ({
  type: actionTypes.SELECTED_CHECK_HEADER,
  payload: data,
});

export const selectedCardHeader = (data) => ({
  type: actionTypes.SELECTED_CARD_HEADER,
  payload: data,
});

export const selectedClaimHeader = (data) => ({
  type: actionTypes.SELECTED_CLAIM_HEADER,
  payload: data,
});

export const selectedClaimAddress = (data) => ({
  type: actionTypes.SELECTED_CLAIM_ADDRESS,
  payload: data,
});

export const selectedClaimFeilds = (data) => ({
  type: actionTypes.SELECTED_CLAIM_FIELDS,
  payload: data,
});

export const selectedLineFields = (data) => ({
  type: actionTypes.SELECTED_LINE_FIELDS,
  payload: data,
});

export const selectedAlsoAddAsFields = (data) => ({
  type: actionTypes.SELECTED_ALSO_ADD_AS_FIELDS,
  payload: data,
});
export const saveClaimFields = (data) => ({
  type: actionTypes.SAVE_CLAIM_FIELDS,
  payload: data,
});

export const saveClaimExtraction = (data) => ({
  type: actionTypes.SAVE_CLAIM_EXTRAXCTION,
  payload: data,
});

export const claimExtractionOpen = (data) => ({
  type: actionTypes.CLAIM_EXTRACTION_OPEN,
  payload: data,
});

export const claimHeaderIdentificationOpen = (data) => ({
  type: actionTypes.CLAIM_HEADER_IDENTIFICATION_OPEN,
  payload: data,
});

export const claimAddressOpen = (data) => ({
  type: actionTypes.CLAIM_ADDRESS_OPEN,
  payload: data,
});

export const lineItemOpen = (data) => ({
  type: actionTypes.LINE_ITEM_OPEN,
  payload: data,
});

export const lineFieldOpen = (data) => ({
  type: actionTypes.LINE_FIELD_OPEN,
  payload: data,
});

export const claimHeaderOpen = (data) => ({
  type: actionTypes.CLAIM_HEADER_OPEN,
  payload: data,
});

export const saveLineItem = (data) => ({
  type: actionTypes.SAVE_LINE_ITEM,
  payload: data,
});

export const saveLineField = (data) => ({
  type: actionTypes.SAVE_LINE_FIELD,
  payload: data,
});

export const claimFieldsOpen = (data) => ({
  type: actionTypes.CLAIM_FIELDS_OPEN,
  payload: data,
});

export const saveClaimHeader = (data) => ({
  type: actionTypes.SAVE_CLAIM_HEADER,
  payload: data,
});

export const saveClaimHeaderIden = (data) => ({
  type: actionTypes.SAVE_CLAIM_HEADER_IDEN,
  payload: data,
});

export const checkAddressOpen = (data) => ({
  type: actionTypes.CHECK_ADDRESS_OPEN,
  payload: data,
});
export const cardAddressOpen = (data) => ({
  type: actionTypes.CARD_ADDRESS_OPEN,
  payload: data,
});

export const saveCheckHeader = (data) => ({
  type: actionTypes.SAVE_CHECK_HEADER,
  payload: data,
});

export const saveCardHeader = (data) => ({
  type: actionTypes.SAVE_CARD_HEADER,
  payload: data,
});
export const checkHeaderOpen = (data) => ({
  type: actionTypes.CHECK_HEADER_OPEN,
  payload: data,
});
export const cardHeaderOpen = (data) => ({
  type: actionTypes.CARD_HEADER_OPEN,
  payload: data,
});

export const changeStatus = (data) => ({
  type: actionTypes.STATUS,
  payload: data,
});

export const saveIncomingData = (data) => ({
  type: actionTypes.GET_SAVED_DATA,
  payload: data,
});

export const saveTemplate = (data) => ({
  type: actionTypes.SAVE_TEMPLATE,
  payload: data,
});

export const saveClaims = (data) => ({
  type: actionTypes.SAVE_CLAIM,
  payload: data,
});

export const saveLine = (data) => ({
  type: actionTypes.SAVE_LINE,
  payload: data,
});

export const dispatchToSaveJson = (data) => ({
  type: actionTypes.SAVE_JSON,
  payload: data,
});

export const dispatchToSubmitJson = (data) => {
  return {
    type: actionTypes.SUBMIT_JSON,
    payload: data,
  };
};

export const saveFromApi = (data) => {
  //debugger;
  return {
    type: actionTypes.SAVE_FROM_API,
    payload: data,
  };
};

export const saveState = (data) => {
  return {
    type: actionTypes.SAVE_STATE,
    payload: data,
  };
};

export const removeCheckStatus = () => {
  return {
    type: actionTypes.REMOVE_CHECK_STATUS,
  };
};

export const errorApi = (data) => {
  return {
    type: actionTypes.ERROR,
    payload: data,
  };
};

export const deleteData = () => ({
  type: actionTypes.DELETE_DATA,
});

export const addExtraBlock = (data) => ({
  type: actionTypes.ADD_EXTRA_BLOCK,
  payload: data,
});

export const deleteExtraBlock = (data) => ({
  type: actionTypes.DELETE_EXTRA_BLOCK,
  payload: data,
});

export const savingData = () => {
  return {
    type: actionTypes.SAVING,
  };
};

export const updateLoading = (data) => {
  return {
    type: actionTypes.LOADING_UPDATE,
    payload: data,
  };
};

export const setNo = (data) => {
  return {
    type: actionTypes.NO,
    payload: data,
  };
};

export const saveCredsInSave = (data) => {
  return {
    type: actionTypes.SAVE_CREDS_IN_SAVE,
    payload: data,
  };
};

export const submitType = (data) => {
  return {
    type: actionTypes.SUBMIT_TYPE,
    payload: data,
  };
};

export const addFileName = (data) => {
  return {
    type: actionTypes.ADD_FILENAME,
    payload: data,
  };
};

export const previewJson = (data) => {
  return {
    type: actionTypes.PREVIEW_JSON,
    payload: data,
  };
};

export const changeLoadingHome = (data) => {
  return {
    type: actionTypes.CHANGE_LOADING_HOME,
    payload: data,
  };
};
const handlePostTemplateConfigure = async (final, dispatch, status, type) => {
  const auth = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("accessToken"))}`,
  };
  try {
    const result = await axios.post(
      `${env.BASE_URL}templateconfigurations`,
      final,
      { headers: auth }
    );
    if (result) {
      dispatch(dispatchToSubmitJson({ status }));
      if (status === "Saved") {
        dispatch(saveState("Saved"));
        // window.location.href = "/form";
      }
      if (status === "Created") {
        dispatch(saveState("Created"));
        if (type === "preview") dispatch(previewJson(result.data));

        //window.location.href = "/home";
      }
      dispatch(changeStatus(""));
    }
  } catch (err) {
    console.log("err", err);
    dispatch(errorApi(err));
    //dispatch(changeStatus("Failed"));
    dispatch(saveState("Failed"));
  }
};

export const submitJson =
  ({ status, name, templateID, fromNavbar }) =>
    async (dispatch, getState) => {
      dispatch(dispatchToSaveJson(status));

      if (status === "Saved") {
        dispatch(saveState("Saving"));
      }
      const save_new = getState()?.save;

      if (status === "Created" && !save_new?.canCreate) return;

      const final = {
        payer_template_id: templateID,
        payer_name: name,
        status,
        template_level_config: save_new.templateJson,
        check_configurations: save_new.checkJson,
        claim_configuration: save_new.claimJson,
        show_preview: save_new.submitType === "preview" ? true : false,
        file_name_for_preview:
          save_new.submitType === "preview" ? save_new.filename : false,
      };

      console.log("final in thunk", final);

      if (getState().save.canCreate && status === "Created") {
        dispatch(saveState("Creating"));
      } else if (status === "Saved") {
        dispatch(saveState("Saving"));
      }

      try {
        validateApi().then((valid) => {
          if (!valid) {
            refreshToken().then(() => {
              handlePostTemplateConfigure(
                final,
                dispatch,
                status,
                save_new.submitType
              );
            });
          } else {
            handlePostTemplateConfigure(
              final,
              dispatch,
              status,
              save_new.submitType
            );
          }
        });
      } catch (err) {
        console.log("err", err);
        dispatch(errorApi(err));
        dispatch(changeStatus("Failed"));
        dispatch(saveState("Failed"));
      }
    };

export const saveJsonFromHome =
  ({ name, templateID }) =>
    async (dispatch, getState) => {
      dispatch(dispatchToSaveJson("Saving"));

      const save_new = getState()?.save;

      const final = {
        payer_template_id: templateID,
        payer_name: name,
        status: "Saved",
        template_level_config: save_new.templateJson,
        check_configurations: save_new.checkJson,
        claim_configuration: save_new.claimJson,
      };
      console.log("final in thunk", final);
      try {
        validateApi().then((valid) => {
          if (!valid) {
            refreshToken().then(() => {
              handlePostTemplateConfigure(final, dispatch, "Saved", "fromHome");
            });
          } else {
            handlePostTemplateConfigure(final, dispatch, "Saved", "fromHome");
          }
        });
      } catch (err) {
        console.log("err", err);
        dispatch(errorApi(err));
        dispatch(changeStatus("Failed"));
        dispatch(saveState("Failed"));
      }
    };
    export const payerIdentificationApi = async (data, fromHome = false) => {
      const auth = {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("accessToken"))}`,
      };
      const json = {
        template_id: data.templateID,
        version_no: data.version,
      };
      if (fromHome) {
        json["file_names"] = data.file_names;
      }
      return await axios.post(`${env.BASE_URL}payeridentification`, json, {
        headers: auth,
      });
    };


const handleGetTemplateConfig = async (data, dispatch) => {
  const auth = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("accessToken"))}`,
  };
  if (data.type === "start") {
    const result = await payerIdentificationApi(data);
    if (result) {
      const json = {
        startStatus: result.status,
        name: data.name,
        message: result.data?.status,
        templateID: data.templateID,
        version: data.version,
        files: result.data.files,
      };

      const result1 = await axios.get(
        `${env.BASE_URL}templateconfigurations?payer_template_id=${data.templateID}&version_no=${data.version}`,
        { headers: auth }
      );

      if (result1) {
        await updateImageDisplayData(result1);
      }

      dispatch(saveCredsInSave(json));
      setTimeout(() => {
        window.location.href = "/form";
      }, 100);
    } else {
      const result1 = await axios.get(
        `${env.BASE_URL}templateconfigurations?payer_template_id=${data.templateID}&version_no=${data.version}`,
        { headers: auth }
      );

      if (result1) {
        await updateImageDisplayData(result1);
      }
    }
  } else {
    const result = await axios.get(
      `${env.BASE_URL}templateconfigurations?payer_template_id=${data.templateID}&version_no=${data.version}`,
      { headers: auth }
    );

    if (result) {
      await updateImageDisplayData(result);
      // setting up redux with incoming data
      const json = result.data.ui_form_json;
      const name = json.payer_name;
      const templateID = json.payer_template_id;
      dispatch(
        saveCredsInSave({
          name,
          templateID,
          version: data.version,
        })
      );
      setTimeout(() => {
        window.location.href = "/form";
      }, 1000);
    }
  }
};
export const getInitialData = (data) => async (dispatch, getState) => {
  dispatch(updateLoading("loading"));
  dispatch(deleteData());
  dispatch(getDropDown());
  try {
    validateApi().then((valid) => {
      if (!valid) {
        refreshToken().then(() => {
          handleGetTemplateConfig(data, dispatch, getState);
        });
      } else {
        handleGetTemplateConfig(data, dispatch, getState);
      }
    });
  } catch (error) {
    console.log(error);
  }
};
const handleGetData = async (data, dispatch, getState) => {
  const auth = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("accessToken"))}`,
  };

  const result = await axios.get(
    `${env.BASE_URL}templateconfigurations?payer_template_id=${data.templateID}&version_no=${data.version}`,
    { headers: auth }
  );
  if (result) {
    await updateImageDisplayData(result);

    // setting up redux with incoming data
    const json = result.data.ui_form_json;
    const name = json.payer_name;
    const templateID = json.payer_template_id;

    dispatch(
      saveCredsInSave({
        name,
        templateID,
        version: data.version,
      })
    );
    let templatePageIdentification1 = [];
    let claimHeaderIden = {};
    let templatePageIdentification2 = [];
    let identification1 = [];
    let negate = [];

    let cladd = [];
    let clfield = [];
    let clhead = [];
    let clheadIden = [];
    let clex = [
      {
        top: "",
        data_top: {},
        data_footer: {},
        data_bottom: {},
        data_heder: {},
        bottom: "",
        header: "",
        footer: "",
        isAdvanced: false,
        bottomLine: 0,
        captureBlockTillNextStart: false,
        pageBreakContinuationIdentifier: "",
        pageBreakdownIdentifier: "",
        isHeaderPresent: false,
        bottomLinesToSkip: 0,
        extractType: true,
      },
    ];
    let lineitem = [
      {
        lineItemIdentifierType: "pcode",
        isAbove: false,
        isBelow: false,
        regex: "",
        date: "",
        lineItemIdentifier: "",
        data: {},
        data_conflictPatternIdentifier: {},
        pcode: "5",
        minLengthOfLine: 1,
        startFromLineIdentifier: true,
        conflictPatternIdentifier: "",
        isAdvanced: false,
        lineStartsWith: "",
        conflictPattern: "",
        conflictPatternRegex: "",
        conflictPatternPcodeLength: 5,
        conflictPatternDate: "",
        aboveConflictPatternRegex: "",
        aboveConflictPatternPcodeLength: 5,
        aboveConflictPatternDate: "",
        belowConflictPatternRegex: "",
        belowConflictPatternPcodeLength: 5,
        belowConflictPatternDate: "",
        wrapLineThreshhold: 1,

        firstDollarBlock: "",
        abovelineMatchingPattern: "date",
        aboveLineMatchingDate: "",
        aboveLineMatchingRegex: "",
        minLengthOfAboveMatchingPcode: 5,
        aboveLineStartBlock: "",
        abovelineConflictPattern: "",
        aboveLineDollarValueBlock: "",
        belowLineMatchingPattern: "date",
        minLengthOfBelowMatchingPcode: 5,
        belowLineMatchingDate: "",
        belowLineMatchingRegex: "",
        belowLineConflictPattern: "",
        belowLineStartBlock: "",
        belowLineDollarValueBlock: "",

        maxLengthOfAboveLineItem: 1,
        minLengthOfAboveLineItem: 1,

        maxLengthofBelowLineItem: 1,
      },
    ];
    let linefp = [];

    let ckadd = [];

    let ckhead = [];
    let cardAdd = [];
    let cardHead = [];
    // getting dropdowns

    const save_new_form = getState().form;

    let checkDropdown = data.checkDropdown.length
      ? data.checkDropdown
      : save_new_form.checkAddress;
    let checkHeaderDropdown = data.checkHeaderDropdown.length
      ? data.checkHeaderDropdown
      : save_new_form.checkHeaders;
    let cardAddressDropdown = data.cardAddressDropdown.length
      ? data.cardAddressDropdown
      : save_new_form.cardAddress;
    let cardHeadersDropdown = data.cardHeadersDropdown.length
      ? data.cardHeadersDropdown
      : save_new_form.cardHeaders;
    let claimAddressDropdown = data.claimAddressDropdown.length
      ? data.claimAddressDropdown
      : save_new_form.claimAddress;
    let claimHeaderDropdown = data.claimHeaderDropdown.length
      ? data.claimHeaderDropdown
      : save_new_form.claimHeaders;
    let claimfieldsDropdown = data.claimfieldsDropdown.length
      ? data.claimfieldsDropdown
      : save_new_form.claimFields;
    let lineFieldsDropdown = data.lineFieldsDropdown.length
      ? data.lineFieldsDropdown
      : save_new_form.lineFields;

    templatePageIdentification1 =
      json?.template_level_config?.payer_template_identification_strings[0]?.map(
        (item, i) =>
          i === 0
            ? {
              isAdd: true,
              block: item.Text,
              data: item,
            }
            : {
              isClose: true,
              block: item.Text,
              data: item,
            }
      );

    // claim header identification

    clheadIden = json.claim_configuration?.claim_header_identification?.map(
      (item, i) =>
        i === 0
          ? {
            isAdd: true,
            block: item.Text,
            data: item,
          }
          : {
            isClose: true,
            block: item.Text,
            data: item,
          }
    );

    templatePageIdentification2 =
      json?.template_level_config?.payer_template_identification_strings[1]?.map(
        (item, i) =>
          i === 0
            ? {
              isAdd: true,
              block: item.Text,
              data: item,
            }
            : {
              isClose: true,
              block: item.Text,
              data: item,
            }
      );

    if (!templatePageIdentification2)
      templatePageIdentification2 = [
        {
          isAdd: true,
          block: "",
          data: {},
        },
      ];

    identification1 =
      json?.template_level_config?.start_identification_strings[0]?.map(
        (item, i) =>
          i === 0
            ? {
              isAdd: true,
              block: item.Text,
              data: item,
            }
            : {
              isClose: true,
              block: item.Text,
              data: item,
            }
      );

    if (!identification1)
      identification1 = [
        {
          isAdd: true,
          block: "",
          data: {},
        },
      ];

    negate = json?.template_level_config?.template_negate_strings?.map(
      (item, i) =>
        i === 0
          ? {
            isAdd: true,
            block: item,
          }
          : {
            isClose: true,
            block: item,
          }
    );

    if (!negate?.length)
      negate = [
        {
          isAdd: true,
          block: "",
        },
      ];

    // line

    const lineItemJson =
      json?.claim_configuration?.line_configuration?.line_items_properties;
    const lineItemJsonFP =
      json?.claim_configuration?.line_configuration?.field_properties;

    lineitem[0].lineItemIdentifierType =
      lineItemJson?.line_item_identifier_type;
    lineitem[0].isAbove = lineItemJson?.isAbove ? true : false;
    lineitem[0].isBelow = lineItemJson?.isBelow ? true : false;
    lineitem[0].minLengthOfAboveLineItem =
      lineItemJson?.min_length_of_above_line_item;

    // lineitem[0].minLengthOfAboveLineItem =
    //   lineItemJson["min_length_of_above_line_item"];

    lineitem[0].conflictPattern = lineItemJson?.line_conflict_pattern_type;

    if (!_.isEmpty(lineItemJson?.line_item_identifier)) {
      lineitem[0].data = lineItemJson.line_item_identifier;
      lineitem[0].lineItemIdentifier =
        lineItemJson.line_item_identifier["Text"];
    }

    if (lineItemJson?.line_conflict_pattern_type === "date") {
      lineitem[0].conflictPatternDate =
        lineItemJson["line_conflict_pattern_format"];
    }

    if (lineItemJson?.line_conflict_pattern_type === "regex") {
      lineitem[0].conflictPatternRegex =
        lineItemJson["line_conflict_pattern_format"];
    }

    if (lineItemJson?.line_conflict_pattern_type === "identifier") {
      if (!_.isEmpty(lineItemJson["line_conflict_pattern_identifier"])) {
        lineitem[0].data_conflictPatternIdentifier =
          lineItemJson["line_conflict_pattern_identifier"];
        lineitem[0].conflictPatternIdentifier =
          lineItemJson["line_conflict_pattern_identifier"]["Text"];
      }
    }

    if (lineItemJson?.line_conflict_pattern_type === "pcode") {
      lineitem[0].conflictPatternPcodeLength =
        lineItemJson["line_conflict_pattern_max_length"];
    }

    lineitem[0].maxLengthOfAboveLineItem =
      lineItemJson?.max_length_of_above_line_item;
    lineitem[0].maxLengthofBelowLineItem =
      lineItemJson?.max_length_of_below_line_item;
    lineitem[0].minLengthOfAboveLineItem =
      lineItemJson?.min_length_of_above_line_item;

    if (!_.isEmpty(lineItemJson["above_line_dollar_value_block"])) {
      lineitem[0].data_aboveLineDollarValueBlock =
        lineItemJson["above_line_dollar_value_block"];
      lineitem[0].aboveLineDollarValueBlock =
        lineItemJson["above_line_dollar_value_block"]["Text"];
    }

    if (!_.isEmpty(lineItemJson["above_line_start_block"])) {
      lineitem[0].data_aboveLineStartBlock =
        lineItemJson["above_line_start_block"];

      lineitem[0].aboveLineStartBlock =
        lineItemJson["above_line_start_block"]["Text"];
    }

    if (!_.isEmpty(lineItemJson["below_line_start_block"])) {
      lineitem[0].data_belowLineStartBlock =
        lineItemJson["below_line_start_block"];

      lineitem[0].belowLineStartBlock =
        lineItemJson["below_line_start_block"]["Text"];
    }

    if (!_.isEmpty(lineItemJson["below_line_dollar_value_block"])) {
      lineitem[0].data_belowLineDollarValueBlock =
        lineItemJson["below_line_dollar_value_block"];
      lineitem[0].belowLineDollarValueBlock =
        lineItemJson["below_line_dollar_value_block"]["Text"];
    }

    lineitem[0].abovelineConflictPattern =
      lineItemJson["above_line_conflict_pattern_type"];

    lineitem[0].abovelineMatchingPattern =
      lineItemJson["above_line_matching_pattern_type"];

    lineitem[0].minLengthOfAboveMatchingPcode =
      lineItemJson["above_line_matching_pattern_max_length"] || 5;

    if (lineItemJson.above_line_matching_pattern_type === "date") {
      lineitem[0].aboveLineMatchingDate =
        lineItemJson["above_line_matching_pattern_format"];
    }
    if (lineItemJson.above_line_matching_pattern_type === "regex") {
      lineitem[0].aboveLineMatchingRegex =
        lineItemJson["above_line_matching_pattern_format"];
    }

    if (lineItemJson.above_line_conflict_pattern_type === "date") {
      lineitem[0].aboveConflictPatternDate =
        lineItemJson["above_line_conflict_pattern_format"];
    }
    if (lineItemJson.above_line_conflict_pattern_type === "regex") {
      lineitem[0].aboveConflictPatternRegex =
        lineItemJson["above_line_conflict_pattern_format"];
    }

    lineitem[0].aboveConflictPatternPcodeLength =
      lineItemJson["above_line_conflict_pattern_max_length"] || 5;

    lineitem[0].belowLineConflictPattern =
      lineItemJson["below_line_conflict_pattern_type"];

    lineitem[0].belowLineMatchingPattern =
      lineItemJson["below_line_matching_pattern_type"];

    lineitem[0].data_belowlineDollarValueBlock =
      lineItemJson["below_line_dollar_value_block"];

    lineitem[0].minLengthOfBelowMatchingPcod =
      lineItemJson["below_line_matching_pattern_max_length"];

    lineitem[0].belowLineMatchingRegex =
      lineItemJson["below_line_matching_pattern_format"];

    lineitem[0].belowLineMatchingDate =
      lineItemJson["below_line_matching_pattern_format"];

    // missing fields

    lineitem[0].pcode = lineItemJson["line_item_identifier_max_length"] || 5;

    // lineItemJson["line_item_identifier_format"] === "date"
    //   ? (lineitem[0].date = lineItemJson["line_item_identifier_format"])
    //   : (lineitem[0].regex = lineItemJson["line_item_identifier_format"]);

    if (lineItemJson["line_item_identifier_format"] === "date") {
      lineitem[0].date = lineItemJson["line_item_identifier_format"];
    }
    if (lineItemJson["line_item_identifier_format"] === "regex") {
      lineitem[0].regex = lineItemJson["line_item_identifier_format"];
    }
    lineitem[0].lineItemIdentifierType = lineItemJson.line_item_identifier_type;

    lineitem[0].belowConflictPatternPcodeLengt =
      lineItemJson["below_line_conflict_pattern_max_length"];

    lineitem[0].minLengthOfBelowLineItem =
      lineItemJson["min_length_of_below_line_item"];

    if (!_.isEmpty(lineItemJson["line_starts_with"])) {
      lineitem[0].data_lineStartsWith = lineItemJson["line_starts_with"];
      lineitem[0].lineStartsWith = lineItemJson["line_starts_with"]["Text"];
    }

    if (!_.isEmpty(lineItemJson["first_dollar_block"])) {
      lineitem[0].data_firstDollarBlock = lineItemJson["first_dollar_block"];
      lineitem[0].firstDollarBlock = lineItemJson["first_dollar_block"]["Text"];
    }

    lineitem[0].belowConflictPatternRegex =
      lineItemJson["below_line_conflict_pattern_format"];

    lineitem[0].belowConflictPatternDate =
      lineItemJson["below_line_conflict_pattern_format"];

    if (lineItemJson.line_item_identifier_type === "date") {
      lineitem[0].date = lineItemJson.line_item_identifier_format;
    }
    if (lineItemJson.line_item_identifier_type === "regex") {
      lineitem[0].regex = lineItemJson.line_item_identifier_format;
    }
    if (lineItemJson.line_item_identifier_type === "pcode") {
      lineitem[0].pcode = lineItemJson.line_item_identifier_max_length;
    }
    lineitem[0].minLengthOfLine = lineItemJson.min_len_of_line_item;
    lineitem[0].startFromLineIdentifier =
      lineItemJson.start_from_line_identifier;
    lineitem[0].wrapLineThreshhold = lineItemJson.wrap_line_threshold;
    //
    let selectedLineField = [];
    let selectedAlsoAddField = [];

    if (lineItemJsonFP.base?.length) {
      lineItemJsonFP.base.map((item, index) => {
        let newfpcopy = {
          dropdown1: "",
          showNewField: false,
          splitLength: 1,
          joinAs: "text",
          dropdown2: "Baseline",
          data: {},
          data1: {},
          data2: {},
          mandatory: false,
          fieldExtractor: true,
          formBasedExtraction: false,
          blockExtractor: false,
          identifier: "",
          block: "",
          block1: "",
          block2: "",
          json: "",
          position: "",
          extractType: "form",
          isOptional: false,
          valueType: "",
          blockType: "text",
          valueBlock: "",
          isAdvanced: false,
          isWrappableFilled: false,
          wrapFrom: "below",
          indexRange1: 0,
          indexRange2: "",
          idlimit: 1,
          noOfBlocks: 1,
          isMultiValue: false,
          alsoAddAs: "",
          alsoAddJson: "",
          wrapValuesInto: "text",
          isSplittable: false,
          captureSplitValueAs: "append to next field",
          newFieldValue: "",
          newFieldValueJson: "",
          newFieldName: "",
          newFieldNameJson: "",
          separatorBasedSplit: true,
          showBlock1: false,
          showBlock2: false,
        };

        newfpcopy.dropdown2 = "Baseline";
        const dd = lineFieldsDropdown.find((it) => it.JsonKey === item.name);
        if (dd) {
          newfpcopy.dropdown = dd.DisplayName;
          if (dd.DisplayName !== "Unwanted")
            selectedLineField = [
              ...selectedLineField,
              { [index]: dd.DisplayName },
            ];
        }

        const adddrop = lineFieldsDropdown.find(
          (it) => it.JsonKey === item.also_add_as
        );

        if (adddrop) {
          {
            newfpcopy.alsoAddAs = adddrop.DisplayName;
            newfpcopy.alsoAddJson = adddrop.JsonKey;

            if (adddrop.DisplayName !== "Unwanted")
              selectedLineField = [
                ...selectedLineField,
                { [index]: adddrop.DisplayName },
              ];
          }
        }
        if (!_.isEmpty(item.Block[0])) {
          newfpcopy.data = item.Block[0];
          newfpcopy.block = item.Block[0].Text;
        }
        if (!_.isEmpty(item.Block[1])) {
          newfpcopy.data1 = item.Block[1];
          newfpcopy.showBlock1 = true;
          newfpcopy.block1 = item.Block[1].Text;
        }

        if (!_.isEmpty(item.Block[2])) {
          newfpcopy.data2 = item.Block[2];
          newfpcopy.block2 = item.Block[2].Text;
          newfpcopy.showBlock2 = true;
        }

        if (item.value_range) {
          newfpcopy.indexRange1 = item.id_limit.split(":")[0];
          newfpcopy.indexRange2 = item.id_limit.split(":")[1];
        }

        newfpcopy.idlimit = item.id_limit;

        newfpcopy.extractType = item.extract_type;
        newfpcopy.addFieldsAs = item["add_fields_as"];
        newfpcopy.isMultiValue = item.is_multi_value;
        newfpcopy.isOptional = item.is_optional;
        newfpcopy.isSplittable = item.is_splittable;
        newfpcopy.isWrappableFilled = item.is_wrappable;
        newfpcopy.wrapFrom = item.wrap_from;

        newfpcopy.joinAs = item.join_as;
        newfpcopy.json = item.name;
        newfpcopy.mandatory = item.is_mandatory;
        const dd2 = lineFieldsDropdown.find(
          (it) => it.JsonKey === item.wrap_new_field_name
        );
        if (dd2) {
          newfpcopy.newFieldNameJson = dd2.JsonKey;
          newfpcopy.newFieldName = dd2.DisplayName;
          if (dd2.DisplayName !== "Unwanted")
            selectedLineField = [
              ...selectedLineField,
              { [index]: dd2.DisplayName },
            ];
        }

        const dd3 = lineFieldsDropdown.find(
          (it) => it.JsonKey === item.split_new_field_name
        );
        if (dd3) {
          newfpcopy.newFieldValueJson = dd3.JsonKey;
          newfpcopy.newFieldValue = dd3.DisplayName;
          if (dd3.DisplayName !== "Unwanted")
            selectedLineField = [
              ...selectedLineField,
              { [index]: dd3.DisplayName },
            ];
        }

        newfpcopy.noOfBlocks = item.no_of_blocks;
        newfpcopy.position = item.position;
        if (item.split_type === "separator") {
          newfpcopy.separatorBasedSplit = true;
        } else newfpcopy.separatorBasedSplit = false;

        newfpcopy.splitLength = item.split_length;
        newfpcopy.valueType = item.value_type;
        newfpcopy.wrapFrom = item.wrap_from;
        newfpcopy.wrapValuesInto = item.wrap_as;
        if (item["split_add_type"]) {
          if (item["split_add_type"] === "mandatory_new_field") {
            newfpcopy.captureSplitValueAs = "MANDATORY new field";
          } else
            newfpcopy.captureSplitValueAs = item["split_add_type"].replace(
              "_",
              " "
            );
        }
        const dd1 = lineFieldsDropdown.find(
          (it) => it.JsonKey === item.split_new_field_name
        );
        if (dd1) {
          newfpcopy.newFieldValueJson = dd1.JsonKey;
          newfpcopy.newFieldValue = dd1.DisplayName;
          if (dd1.DisplayName !== "Unwanted")
            selectedLineField = [
              ...selectedLineField,
              { [index]: dd1.DisplayName },
            ];
        }
        linefp = [...linefp, newfpcopy];
      });
    }

    if (lineItemJsonFP.above?.length) {
      lineItemJsonFP.above.map((item, index) => {
        let newfpcopy = {
          dropdown1: "",
          showNewField: false,
          splitLength: 1,
          joinAs: "text",
          dropdown2: "Above",
          data: {},
          data1: {},
          data2: {},
          mandatory: false,
          fieldExtractor: true,
          formBasedExtraction: false,
          blockExtractor: false,
          identifier: "",
          block: "",
          block1: "",
          block2: "",
          json: "",
          position: "",
          extractType: "form",
          isOptional: false,
          valueType: "ANY",
          blockType: "text",
          valueBlock: "",
          isAdvanced: false,
          isWrappableFilled: false,
          wrapFrom: "below",
          indexRange1: 0,
          indexRange2: "",
          idlimit: 1,
          noOfBlocks: 1,
          isMultiValue: false,
          alsoAddAs: "",
          alsoAddJson: "",
          wrapValuesInto: "text",
          isSplittable: false,
          captureSplitValueAs: "append to next field",
          newFieldValue: "",
          newFieldValueJson: "",
          newFieldName: "",
          newFieldNameJson: "",
          separatorBasedSplit: true,
          showBlock1: false,
          showBlock2: false,
        };

        newfpcopy.dropdown2 = "Above";
        const dd = lineFieldsDropdown.find((it) => it.JsonKey === item.name);
        if (dd) {
          newfpcopy.dropdown = dd.DisplayName;
          if (dd.DisplayName !== "Unwanted")
            selectedLineField = [
              ...selectedLineField,
              { [index]: dd.DisplayName },
            ];
        }

        const adddrop = lineFieldsDropdown.find(
          (it) => it.JsonKey === item.also_add_as
        );

        if (adddrop) {
          {
            newfpcopy.alsoAddAs = adddrop.DisplayName;
            newfpcopy.alsoAddJson = adddrop.JsonKey;

            if (adddrop.DisplayName !== "Unwanted")
              selectedLineField = [
                ...selectedLineField,
                { [index]: adddrop.DisplayName },
              ];
          }
        }
        if (!_.isEmpty(item.Block[0])) {
          newfpcopy.data = item.Block[0];
          newfpcopy.block = item.Block[0].Text;
        }
        if (!_.isEmpty(item.Block[1])) {
          newfpcopy.data1 = item.Block[1];
          newfpcopy.showBlock1 = true;
          newfpcopy.block1 = item.Block[1].Text;
        }

        if (!_.isEmpty(item.Block[2])) {
          newfpcopy.data2 = item.Block[2];
          newfpcopy.block2 = item.Block[2].Text;
          newfpcopy.showBlock2 = true;
        }

        if (item.value_range) {
          newfpcopy.indexRange1 = item.id_limit.split(":")[0];
          newfpcopy.indexRange2 = item.id_limit.split(":")[1];
        }

        newfpcopy.idlimit = item.id_limit;

        newfpcopy.extractType = item.extract_type;
        newfpcopy.addFieldsAs = item["add_fields_as"];
        newfpcopy.isMultiValue = item.is_multi_value;
        newfpcopy.isOptional = item.is_optional;
        newfpcopy.isSplittable = item.is_splittable;
        newfpcopy.isWrappableFilled = item.is_wrappable;
        newfpcopy.wrapFrom = item.wrap_from;
        newfpcopy.wrapValuesInto = item.wrap_as;
        newfpcopy.joinAs = item.join_as;
        newfpcopy.json = item.name;
        newfpcopy.mandatory = item.is_mandatory;
        const dd2 = lineFieldsDropdown.find(
          (it) => it.JsonKey === item.wrap_new_field_name
        );
        if (dd2) {
          newfpcopy.newFieldNameJson = dd2.JsonKey;
          newfpcopy.newFieldName = dd2.DisplayName;
          if (dd2.DisplayName !== "Unwanted")
            selectedLineField = [
              ...selectedLineField,
              { [index]: dd2.DisplayName },
            ];
        }

        const dd3 = lineFieldsDropdown.find(
          (it) => it.JsonKey === item.split_new_field_name
        );
        if (dd3) {
          newfpcopy.newFieldValueJson = dd3.JsonKey;
          newfpcopy.newFieldValue = dd3.DisplayName;
          if (dd3.DisplayName !== "Unwanted")
            selectedLineField = [
              ...selectedLineField,
              { [index]: dd3.DisplayName },
            ];
        }

        newfpcopy.noOfBlocks = item.no_of_blocks;
        newfpcopy.position = item.position;
        if (item.split_type === "separator") {
          newfpcopy.separatorBasedSplit = true;
        } else newfpcopy.separatorBasedSplit = false;

        newfpcopy.splitLength = item.split_length;
        newfpcopy.valueType = item.value_type;
        newfpcopy.wrapFrom = item.wrap_from;
        newfpcopy.wrapValuesInto = item.wrap_as;
        if (item["split_add_type"]) {
          if (item["split_add_type"] === "mandatory_new_field") {
            newfpcopy.captureSplitValueAs = "MANDATORY new field";
          } else
            newfpcopy.captureSplitValueAs = item["split_add_type"].replace(
              "_",
              " "
            );
        }
        const dd1 = lineFieldsDropdown.find(
          (it) => it.JsonKey === item.split_new_field_name
        );
        if (dd1) {
          newfpcopy.newFieldValueJson = dd1.JsonKey;
          newfpcopy.newFieldValue = dd1.DisplayName;
          if (dd1.DisplayName !== "Unwanted")
            selectedLineField = [
              ...selectedLineField,
              { [index]: dd1.DisplayName },
            ];
        }
        linefp = [...linefp, newfpcopy];
      });
    }

    if (lineItemJsonFP.below?.length) {
      lineItemJsonFP.below.map((item, index) => {
        let newfpcopy = {
          dropdown1: "",
          showNewField: false,
          splitLength: 1,
          joinAs: "text",
          dropdown2: "Below",
          data: {},
          data1: {},
          data2: {},
          mandatory: false,
          fieldExtractor: true,
          formBasedExtraction: false,
          blockExtractor: false,
          identifier: "",
          block: "",
          block1: "",
          block2: "",
          json: "",
          position: "",
          extractType: "form",
          isOptional: false,
          valueType: "ANY",
          blockType: "text",
          valueBlock: "",
          isAdvanced: false,
          isWrappableFilled: false,
          wrapFrom: "below",
          indexRange1: 0,
          indexRange2: "",
          idlimit: 1,
          noOfBlocks: 1,
          isMultiValue: false,
          alsoAddAs: "",
          alsoAddJson: "",
          wrapValuesInto: "text",
          isSplittable: false,
          captureSplitValueAs: "append to next field",
          newFieldValue: "",
          newFieldValueJson: "",
          newFieldName: "",
          newFieldNameJson: "",
          separatorBasedSplit: true,
          showBlock1: false,
          showBlock2: false,
        };

        newfpcopy.dropdown2 = "Below";
        const dd = lineFieldsDropdown.find((it) => it.JsonKey === item.name);
        if (dd) {
          newfpcopy.dropdown = dd.DisplayName;
          if (dd.DisplayName !== "Unwanted")
            selectedLineField = [
              ...selectedLineField,
              { [index]: dd.DisplayName },
            ];
        }
        newfpcopy.data = item.Block;
        const adddrop = lineFieldsDropdown.find(
          (it) => it.JsonKey === item.also_add_as
        );
        if (adddrop) {
          {
            newfpcopy.alsoAddAs = adddrop.DisplayName;
            newfpcopy.alsoAddJson = adddrop.JsonKey;

            if (adddrop.DisplayName !== "Unwanted")
              selectedLineField = [
                ...selectedLineField,
                { [index]: adddrop.DisplayName },
              ];
          }
        }

        if (!_.isEmpty(item.Block[0])) {
          newfpcopy.data = item.Block[0];
          newfpcopy.block = item.Block[0].Text;
        }
        if (!_.isEmpty(item.Block[1])) {
          newfpcopy.data1 = item.Block[1];
          newfpcopy.showBlock1 = true;
          newfpcopy.block1 = item.Block[1].Text;
        }

        if (!_.isEmpty(item.Block[2])) {
          newfpcopy.data2 = item.Block[2];
          newfpcopy.block2 = item.Block[2].Text;
          newfpcopy.showBlock2 = true;
        }

        if (item.value_range) {
          newfpcopy.indexRange1 = item.id_limit.split(":")[0];
          newfpcopy.indexRange2 = item.id_limit.split(":")[1];
        }

        newfpcopy.idlimit = item.id_limit;
        newfpcopy.addFieldsAs = item["add_fields_as"];
        newfpcopy.extractType = item.extract_type;
        newfpcopy.isMultiValue = item.is_multi_value;
        newfpcopy.alsoAddAs = item.also_add_As;
        newfpcopy.isOptional = item.is_optional;
        newfpcopy.isSplittable = item.is_splittable;
        newfpcopy.isWrappableFilled = item.is_wrappable;
        newfpcopy.wrapFrom = item.wrap_from;
        newfpcopy.wrapValuesInto = item.wrap_as;
        newfpcopy.joinAs = item.join_as;
        newfpcopy.json = item.name;
        newfpcopy.mandatory = item.is_mandatory;
        const dd2 = lineFieldsDropdown.find(
          (it) => it.JsonKey === item.wrap_new_field_name
        );
        if (dd2) {
          newfpcopy.newFieldNameJson = dd2.JsonKey;
          newfpcopy.newFieldName = dd2.DisplayName;
          if (dd2.DisplayName !== "Unwanted")
            selectedLineField = [
              ...selectedLineField,
              { [index]: dd2.DisplayName },
            ];
        }
        const dd3 = lineFieldsDropdown.find(
          (it) => it.JsonKey === item.split_new_field_name
        );
        if (dd3) {
          newfpcopy.newFieldValueJson = dd3.JsonKey;
          newfpcopy.newFieldValue = dd3.DisplayName;
          if (dd3.DisplayName !== "Unwanted")
            selectedLineField = [
              ...selectedLineField,
              { [index]: dd3.DisplayName },
            ];
        }

        newfpcopy.newFieldValue = item.new_field_value;
        newfpcopy.newFieldValueJson = item.new_field_value;
        newfpcopy.noOfBlocks = item.no_of_blocks;
        newfpcopy.position = item.position;
        if (item.split_type === "separator")
          newfpcopy.separatorBasedSplit = true;
        else newfpcopy.separatorBasedSplit = false;

        newfpcopy.splitLength = item.split_length;
        newfpcopy.valueType = item.value_type;
        newfpcopy.wrapFrom = item.wrap_from;
        newfpcopy.wrapValuesInto = item.wrap_as;
        if (item["split_add_type"]) {
          if (item["split_add_type"] === "mandatory_new_field") {
            newfpcopy.captureSplitValueAs = "MANDATORY new field";
          } else
            newfpcopy.captureSplitValueAs = item["split_add_type"].replace(
              "_",
              " "
            );
        }
        const dd1 = lineFieldsDropdown.find(
          (it) => it.JsonKey === item.split_new_field_name
        );
        if (dd1) {
          newfpcopy.newFieldValueJson = dd1.JsonKey;
          newfpcopy.newFieldValue = dd1.DisplayName;
          if (dd1.DisplayName !== "Unwanted")
            selectedLineField = [
              ...selectedLineField,
              { [index]: dd1.DisplayName },
            ];
        }

        linefp = [...linefp, newfpcopy];
      });
    }

    if (linefp.length > 1) {
      linefp = linefp.map((item) => ({
        ...item,
        isAdd: true,
        isClose: true,
      }));
    } else
      linefp = linefp.map((item) => ({
        ...item,
        isAdd: true,
      }));

    // claim extraction

    const clExJson = json.claim_configuration.claim_extraction_properties;

    if (
      json.claim_configuration.claim_extraction_properties?.top_identifier &&
      json.claim_configuration.claim_extraction_properties?.top_identifier
        .length
    ) {
      clex[0].top =
        json.claim_configuration.claim_extraction_properties?.top_identifier[0]?.Text;
      clex[0].data_top =
        json.claim_configuration.claim_extraction_properties?.top_identifier[0];
    }

    if (
      json.claim_configuration.claim_extraction_properties?.bottom_identifier &&
      json.claim_configuration.claim_extraction_properties?.bottom_identifier
        .length
    ) {
      clex[0].bottom =
        json.claim_configuration.claim_extraction_properties?.bottom_identifier[0]?.Text;
      clex[0].data_bottom =
        json.claim_configuration.claim_extraction_properties?.bottom_identifier[0];
    }

    clex[0].bottomLine = clExJson?.bottom_additional_line;
    if (clExJson?.is_header_present)
      clex[0].isHeaderPresent = clExJson?.is_header_present;
    else if (clExJson?.extract_type)
      clex[0].isHeaderPresent =
        clExJson?.extract_type === "header" ? true : false;
    clex[0].captureBlockTillNextStart =
      clExJson?.capture_blocks_till_next_start;
    clex[0].bottomLinesToSkip = clExJson?.bottom_line_to_skip;

    if (clExJson?.page_break_cont_identifier) {
      clex[0]["data_pbci"] = clExJson?.page_break_cont_identifier;
      clex[0]["pageBreakContinuationIdentifier"] =
        clExJson?.page_break_cont_identifier["Text"];
    }
    if (clExJson?.page_break_identifier) {
      clex[0]["data_pbi"] = clExJson?.page_break_identifier;
      clex[0]["pageBreakdownIdentifier"] =
        clExJson?.page_break_identifier["Text"];
    }
    if (clExJson?.header_identifier) {
      clex[0]["data_heder"] = clExJson?.header_identifier;
      clex[0]["header"] = clExJson?.header_identifier["Text"];
    }
    if (clExJson?.footer_identifier) {
      clex[0]["data_footer"] = clExJson?.footer_identifier;
      clex[0]["footer"] = clExJson?.footer_identifier["Text"];
    }

    // claim header
    const claimheaderjson = json.claim_configuration.claim_header_fields;
    let selectedClaimHeader = [];
    claimheaderjson.map((item, index) => {
      let clheadjson = {
        dropdown: "",
        isActive: true,
        json: "",
        mandatory: false,
        formBasedExtractor: true,
        blockExtractor: false,

        extractType: "form",
        data: {},
        data_end_identifier: {},
        identifier: "",
        endIdentifier: "",
        idlimit: "1",
        indexRange1: "0",
        indexRange2: "",
        isAdvanced: false,
        noOfBlocks: 1,
        values: "",
        valueType: "text",
      };

      if (item.hasOwnProperty("identifier") && !_.isEmpty(item.identifier)) {
        clheadjson.identifier = item.identifier?.Text;
        clheadjson.data = item.identifier;
        clheadjson.extractType = item.extract_type;
        clheadjson.blockExtractor = false;
        clheadjson.formBasedExtractor = true;
        if (!_.isEmpty(item?.value)) {
          clheadjson.data_value = item.value;
          clheadjson.value = item.value?.Text;
        }
      } else if (!_.isEmpty(item.Blocks)) {
        clheadjson.formBasedExtractor = false;
        clheadjson.blockExtractor = true;
        clheadjson.data = item.Blocks;
        clheadjson.values = item.Blocks?.Text;
        clheadjson.valueType = item.value_type;
      }

      clheadjson.data_end_identifier = item?.end_identifier;
      clheadjson.endIdentifier = item?.end_identifier?.Text;

      if (item?.value_range) {
        clheadjson.indexRange1 = item?.value_range.split(":")[0];
        clheadjson.indexRange2 = item?.value_range.split(":")[1];
      }

      const dd = claimHeaderDropdown.find((it) => it.JsonKey === item.name);

      if (dd) {
        clheadjson.dropdown = dd.DisplayName;
        selectedClaimHeader = [
          ...selectedClaimHeader,
          { [index]: dd.DisplayName },
        ];
      }

      clheadjson.idlimit = item.id_limit || 1;
      clheadjson.noOfBlocks = item?.look_up_till || 1;
      clheadjson.json = item?.name;
      clheadjson.mandatory = item.is_mandatory;
      if (item.no_of_blocks) clheadjson.noOfBlocks;

      clhead = [...clhead, clheadjson];
    });

    if (clhead.length > 1) {
      clhead = clhead.map((item) => ({
        ...item,
        isAdd: true,
        isClose: true,
      }));
    } else
      clhead = clhead.map((item) => ({
        ...item,
        isAdd: true,
      }));

    let selectedClaimFields = [];
    const claimfieldsjson = json.claim_configuration.form_fields;
    claimfieldsjson.map((item, index) => {
      let clfieldjson = {
        mandatory: false,
        regex: "",
        nextValueType: "",
        prevValueType: "",
        indexRegexPrev: "",
        minLength: 1,
        nextValueDate: "",
        prevValueDate: "",
        maxLength: 256,
        nameType: "first_last_middle",
        json: "",
        line: 0,
        indexRangeIndexExtractor2: "",
        indexRangeIndexExtractor1: 0,
        showBlock1: false,
        showBlock2: false,
        isReset: true,
        block: "",
        data: {},

        keyValueExtractor: true,
        indexBasedExtractor: false,
        dropdown: "",
        identifier: "",
        extractType: "form",
        isAdvanced: false,
        endIdentifier: "",
        idlimit: "1",
        indexRange1: "0",
        indexRange2: "",
        noOfBlocks: "1",
        isNameType: false,
        data_end_identifier: {},
      };

      const dd = claimfieldsDropdown.find((cl) => cl.JsonKey === item.name);
      if (dd) {
        clfieldjson.dropdown = dd.DisplayName;
        if (
          dd.DisplayName.includes("Name") &&
          dd.DisplayName !== "Payee Name In EOB (C6)"
        ) {
          clfieldjson.isNameType = true;
          clfieldjson.nameType = item["name_type"];
        }
        selectedClaimFields = [
          ...selectedClaimFields,
          { [index]: dd.DisplayName },
        ];
      }

      if (item?.value_range) {
        if (item?.extractor === "key") {
          clfieldjson.indexRange1 = item?.value_range.split(":")[0];
          clfieldjson.indexRange2 = item?.value_range.split(":")[1];
        } else if (item?.extractor === "index") {
          clfieldjson.indexRangeIndexExtractor1 =
            item?.value_range.split(":")[0];
          clfieldjson.indexRangeIndexExtractor2 =
            item?.value_range.split(":")[1];
        }
        //  for older templates
        else if (!item?.extractor) {
          clfieldjson.indexRange1 = item?.value_range.split(":")[0];
          clfieldjson.indexRange2 = item?.value_range.split(":")[1];
          clfieldjson.indexRangeIndexExtractor1 =
            item?.value_range.split(":")[0];
          clfieldjson.indexRangeIndexExtractor2 =
            item?.value_range.split(":")[1];
        }
      }

      if (item.extractor === "key") {
        if (!_.isEmpty(item?.identifier)) {
          clfieldjson.data = item.identifier;
          clfieldjson.identifier = item.identifier?.Text;
        }
        if (!_.isEmpty(item?.value)) {
          clfieldjson.data_value = item.value;
          clfieldjson.value = item.value?.Text;
        }
        clfieldjson.keyValueExtractor = true;
        clfieldjson.indexBasedExtractor = false;
        clfieldjson.valueType = item.value_type;

        clfieldjson.extractType = item.extract_type;
        clfieldjson.idlimit = item.id_limit;
        clfieldjson.noOfBlocks = item?.look_up_till;

        clfieldjson.data_identifier = item.identifier;

        clfieldjson.endIdentifier = item?.end_identifier?.Text;
        clfieldjson.data_end_identifier = item.end_identifier;
      } else if (item.extractor === "index") {
        clfieldjson.indexBasedExtractor = true;
        clfieldjson.keyValueExtractor = false;
        clfieldjson.line = item?.line_number;

        if (!_.isEmpty(item.identifier)) {
          clfieldjson.data = item.identifier;
          clfieldjson.block = item.identifier?.Text;
        }

        if (item.value_type === "date") {
          clfieldjson.valueType = "date";
          clfieldjson.date = item.value_format;
        }
        if (item.value_type === "regex") {
          clfieldjson.valueType = "regex";
          clfieldjson.regex = item.value_format;
        } else {
          clfieldjson.valueType = item.value_type;
        }

        clfieldjson.minLength = item.min_length_of_block;
        clfieldjson.maxLength = item.max_length_of_block;
        if (item?.next_value_type === "regex") {
          clfieldjson.nextValueType = "regex";
          clfieldjson.indexRegex = item?.next_value_format;
        }
        if (item?.next_value_type === "date") {
          clfieldjson.nextValueType = "date";
          clfieldjson.nextValueDate = item?.next_value_format;
        } else clfieldjson.nextValueType = item?.next_value_type;

        if (item?.prev_value_type === "regex") {
          clfieldjson.prevValueType = "regex";
          clfieldjson.indexRegexPrev = item?.prev_value_format;
        }
        if (item?.prev_value_type === "date") {
          clfieldjson.prevValueType = "date";
          clfieldjson.prevValueDate = item?.prev_value_format;
        } else clfieldjson.prevValueType = item?.prev_value_type;
      }

      // for older templates
      else if (!item?.extractor) {
        if (!_.isEmpty(item?.identifier)) {
          clfieldjson.data = item.identifier;
          clfieldjson.identifier = item.identifier?.Text;
        }

        if (!_.isEmpty(item?.value)) {
          clfieldjson.data_value = item.value;
          clfieldjson.value = item.data_value?.Text;
        }
        clfieldjson.keyValueExtractor = true;
        clfieldjson.indexBasedExtractor = false;
        clfieldjson.valueType = item.value_type;

        clfieldjson.extractType = item.extract_type;
        clfieldjson.idlimit = item.id_limit;
        clfieldjson.noOfBlocks = item?.look_up_till;

        clfieldjson.data_identifier = item.identifier;
        clfieldjson.endIdentifier = item?.end_identifier?.Text;
        clfieldjson.data_end_identifier = item.end_identifier;

        // index based

        clfieldjson.line = item?.line_number;

        if (item.value_type === "date") {
          clfieldjson.valueType = "date";
          clfieldjson.date = item.value_format;
        }
        if (item.value_type === "regex") {
          clfieldjson.valueType = "regex";
          clfieldjson.regex = item.value_format;
        } else {
          clfieldjson.valueType = item.value_type;
        }

        if (!_.isEmpty(item?.value)) {
          clfieldjson.data_value = item.value;
          clfieldjson.value = item.data_value?.Text;
        }

        clfieldjson.minLength = item.min_length_of_block;
        clfieldjson.maxLength = item.max_length_of_block;
        if (item?.next_value_type === "regex") {
          clfieldjson.nextValueType = "regex";
          clfieldjson.indexRegex = item?.next_value_format;
        }
        if (item?.next_value_type === "date") {
          clfieldjson.nextValueType = "date";
          clfieldjson.nextValueDate = item?.next_value_format;
        } else clfieldjson.nextValueType = item?.next_value_type;

        if (item?.prev_value_type === "regex") {
          clfieldjson.prevValueType = "regex";
          clfieldjson.indexRegexPrev = item?.prev_value_format;
        }
        if (item?.prev_value_type === "date") {
          clfieldjson.prevValueType = "date";
          clfieldjson.prevValueDate = item?.prev_value_format;
        } else clfieldjson.prevValueType = item?.prev_value_type;
      }
      clfieldjson.mandatory = item.is_mandatory;
      clfieldjson.json = item.name;
      if (!_.isEmpty(item?.value)) {
        clfieldjson.data_value = item.value;
        clfieldjson.value = item.value?.Text;
      }
      clfield = [...clfield, clfieldjson];
    });

    if (clfield.length > 1) {
      clfield = clfield.map((item) => ({
        ...item,
        isAdd: true,
        isClose: true,
      }));
    } else
      clfield = clfield.map((item) => ({
        ...item,
        isAdd: true,
      }));

    //claim address
    let selectedClaimAddress = [];
    const claimaddressjson = json.claim_configuration.claim_address_fields;
    claimaddressjson.map((item, index) => {
      let block = "";
      let claimaddjson = {
        dropdown: "",
        json: "",
        isActive: true,
        mandatory: false,
        blocks: "",
        minLenOfAddressLine: 1,
        isAdd: false,
      };
      if (item?.Blocks && item?.Blocks.length) {
        block = item?.Blocks[0];
        claimaddjson.blocks = block?.Text;
        claimaddjson.data = block;
      }

      const dd = claimAddressDropdown.find((dr) => dr.JsonKey === item.name);
      if (dd) {
        claimaddjson.dropdown = dd.DisplayName;
        claimaddjson.json = dd.JsonKey;
        selectedClaimAddress = [
          ...selectedClaimAddress,
          { [index]: dd.DisplayName },
        ];
      }

      claimaddjson.mandatory = item.mandatory;

      claimaddjson.minLenOfAddressLine = item.min_len_of_address_lines;
      cladd = [...cladd, claimaddjson];
    });

    if (cladd.length > 1) {
      cladd = cladd.map((item) => ({
        ...item,
        isAdd: true,
        isClose: true,
      }));
    } else
      cladd = cladd.map((item) => ({
        ...item,
        isAdd: true,
      }));

    // check address and headers

    const checkaddressjson = json.check_configurations.check_address_fields;
    let selectedCheckAddr = [];
    checkaddressjson.map((item, index) => {
      let block = "";
      let ckeckAddJson = {
        dropdown: "",
        default: true,
        json: "",
        isActive: false,
        mandatory: false,
        data: {},
        minLenOfAddressLine: 1,
        isAdd: false,
        blocks: "",
      };
      if (item?.Blocks && item?.Blocks.length) {
        block = item?.Blocks[0];
        ckeckAddJson.blocks = block?.Text;
        ckeckAddJson.data = block;
      }

      const dd = checkDropdown.find((dr) => dr.JsonKey === item.name);

      if (dd) {
        ckeckAddJson.json = item.name;
        ckeckAddJson.dropdown = dd.DisplayName;
        selectedCheckAddr = [...selectedCheckAddr, { [index]: dd.DisplayName }];
      }
      ckeckAddJson.mandatory = item.is_mandatory;

      ckeckAddJson.minLenOfAddressLine = item.min_len_of_address_lines;

      ckadd = [...ckadd, ckeckAddJson];
    });

    if (ckadd.length > 1) {
      ckadd = ckadd.map((item) => ({
        ...item,
        isAdd: true,
        isClose: true,
      }));
    } else
      ckadd = ckadd.map((item) => ({
        ...item,
        isAdd: true,
      }));

    //card address

    const cardaddressjson = json.check_configurations?.card_address_fields;
    let selectedCardAddr = [];
    if (cardaddressjson) {
      cardaddressjson.map((item, index) => {
        let block = "";
        let cardAddJson = {
          dropdown: "",
          default: true,
          json: "",
          isActive: false,
          mandatory: false,
          data: {},
          minLenOfAddressLine: 1,
          isAdd: false,
          blocks: "",
        };
        if (item?.Blocks && item?.Blocks.length) {
          block = item?.Blocks[0];
          cardAddJson.blocks = block?.Text;
          cardAddJson.data = block;
        }

        const dd = cardAddressDropdown.find((dr) => dr.JsonKey === item.name);

        if (dd) {
          cardAddJson.json = item.name;
          cardAddJson.dropdown = dd.DisplayName;
          selectedCardAddr = [...selectedCardAddr, { [index]: dd.DisplayName }];
        }
        cardAddJson.mandatory = item.is_mandatory;

        cardAddJson.minLenOfAddressLine = item.min_len_of_address_lines;

        cardAdd = [...cardAdd, cardAddJson];
      });

      if (cardAdd.length > 1) {
        cardAdd = cardAdd.map((item) => ({
          ...item,
          isAdd: true,
          isClose: true,
        }));
      } else
        cardAdd = cardAdd.map((item) => ({
          ...item,
          isAdd: true,
        }));
    }

    //check header
    let selectedCheckHead = [];
    const checkheaderjson = json.check_configurations?.check_header_fields;

    checkheaderjson.map((item, index) => {
      let checkHeaderJson = {
        dropdown: "",
        isBlockActive: false,
        mandatory: false,
        data: "",
        blockExtractor: false,
        formBasedExtractor: true,
        identifier: "",
        isEndIdentifierActive: false,
        valueBlock: "",
        extractType: "form",
        endIdentifier: "",
        idlimit: "1",
        indexRange1: "0",
        indexRange2: "",
        isAdvanced: "",
        noOfBlocks: 1,
      };

      if (!_.isEmpty(item.end_identifier)) {
        checkHeaderJson.data_endIdentifier = item.end_identifier;
        checkHeaderJson.endIdentifier = item.end_identifier["Text"];
      }

      if (item.identifier || !_.isEmpty(item.identifier)) {
        checkHeaderJson.identifier = item.identifier?.Text;
        checkHeaderJson.data = item.identifier;
        checkHeaderJson.extractType = item.extract_type;
        checkHeaderJson.blockExtractor = false;
        checkHeaderJson.formBasedExtractor = true;
        if (!_.isEmpty(item?.value)) {
          checkHeaderJson.data_value = item.value;
          checkHeaderJson.value = item.value?.Text;
        }
      } else {
        let block = "";
        if (!_.isEmpty(item?.Blocks)) {
          block = item?.Blocks;

          checkHeaderJson.valueBlock = block?.Text;
          checkHeaderJson.valueType = item.value_type;
          checkHeaderJson.data = block;
          checkHeaderJson.blockExtractor = true;
          checkHeaderJson.formBasedExtractor = false;
        }
      }
      const dd = checkHeaderDropdown.find((dr) => dr.JsonKey === item.name);
      if (dd) {
        checkHeaderJson.dropdown = dd.DisplayName;
        selectedCheckHead = [...selectedCheckHead, { [index]: dd.DisplayName }];
      }
      checkHeaderJson.mandatory = item.is_mandatory;
      checkHeaderJson.json = item.name;
      checkHeaderJson.idlimit = item.id_limit || 1;
      checkHeaderJson.noOfBlocks = item?.look_up_till || 1;
      if (item.value_range) {
        const vr = item.value_range.split(":");
        checkHeaderJson.indexRange1 = vr[0] || 0;
        checkHeaderJson.indexRange2 = vr[1];
      }
      checkHeaderJson.minLenOfAddressLine = item.min_len_of_address_lines;
      ckhead = [...ckhead, checkHeaderJson];
    });

    if (ckhead.length > 1) {
      ckhead = ckhead.map((item) => ({
        ...item,
        isAdd: true,
        isClose: true,
      }));
    } else
      ckhead = ckhead.map((item) => ({
        ...item,
        isAdd: true,
      }));

    //card header

    let selectedCardHead = [];
    const cardheaderjson = json.check_configurations?.card_header_fields;
    if (cardheaderjson) {
      cardheaderjson.map((item, index) => {
        let cardHeaderJson = {
          dropdown: "Reference Number",
          isBlockActive: false,
          mandatory: false,
          data: "",
          blockExtractor: false,
          formBasedExtractor: true,
          identifier: "",
          isEndIdentifierActive: false,
          valueBlock: "",
          extractType: "form",
          endIdentifier: "",
          idlimit: "1",
          indexRange1: "0",
          indexRange2: "",
          isAdvanced: "",
          noOfBlocks: 1,
        };

        if (!_.isEmpty(item.end_identifier)) {
          cardHeaderJson.data_endIdentifier = item.end_identifier;
          cardHeaderJson.endIdentifier = item.end_identifier["Text"];
        }

        if (item.identifier || !_.isEmpty(item.identifier)) {
          cardHeaderJson.identifier = item.identifier?.Text;
          cardHeaderJson.data = item.identifier;
          cardHeaderJson.extractType = item.extract_type;
          cardHeaderJson.blockExtractor = false;
          cardHeaderJson.formBasedExtractor = true;
          if (!_.isEmpty(item?.value)) {
            cardHeaderJson.data_value = item.value;
            cardHeaderJson.value = item.value?.Text;
          }
        } else {
          let block = "";
          if (!_.isEmpty(item?.Blocks)) {
            block = item?.Blocks;

            cardHeaderJson.valueBlock = block?.Text;
            cardHeaderJson.valueType = item.value_type;
            cardHeaderJson.data = block;
            cardHeaderJson.blockExtractor = true;
            cardHeaderJson.formBasedExtractor = false;
          }
        }
        const dd = cardHeadersDropdown.find((dr) => dr.JsonKey === item.name);
        if (dd) {
          cardHeaderJson.dropdown = dd.DisplayName;
          selectedCardHead = [...selectedCardHead, { [index]: dd.DisplayName }];
        }
        cardHeaderJson.mandatory = item.is_mandatory;
        cardHeaderJson.json = item.name;
        cardHeaderJson.idlimit = item.id_limit || 1;
        cardHeaderJson.noOfBlocks = item?.look_up_till || 1;
        if (item.value_range) {
          const vr = item.value_range.split(":");
          cardHeaderJson.indexRange1 = vr[0] || 0;
          cardHeaderJson.indexRange2 = vr[1];
        }
        cardHeaderJson.minLenOfAddressLine = item.min_len_of_address_lines;
        cardHead = [...cardHead, cardHeaderJson];
      });

      if (cardHead.length > 1) {
        cardHead = cardHead.map((item) => ({
          ...item,
          isAdd: true,
          isClose: true,
        }));
      } else
        cardHead = cardHead.map((item) => ({
          ...item,
          isAdd: true,
        }));
    }

    dispatch(
      saveFromApi({
        linefp,
        clex,
        clhead,
        clheadIden,
        clfield,
        cladd,
        ckadd,
        ckhead,
        cardAdd,
        cardHead,
        lineitem,
        templatePageIdentification1,
        templatePageIdentification2,
        identification1,
        claimHeaderIden,
        negate,
        name,
        templateID,
        selectedCheckHead,
        selectedCheckAddr,
        selectedCardAddr,
        selectedCardHead,
        selectedClaimHeader,
        selectedClaimFields,
        selectedClaimAddress,
        selectedLineField,
        selectedAlsoAddField,
      })
    );
    //  dispatch(updateLoading("loaded"));
  }
};
export const getSavedData = (data) => async (dispatch, getState) => {
  dispatch(updateLoading("loading"));
  dispatch(deleteData());
  // dispatch(getDropDown());

  try {
    validateApi().then((valid) => {
      if (!valid) {
        refreshToken().then(() => {
          handleGetData(data, dispatch, getState);
        });
      } else {
        handleGetData(data, dispatch, getState);
      }
    });
  } catch (err) {
    console.log("err", err);
  }
};

const updateImageDisplayData = (response) => {
  keysToRemove.forEach((k) => localStorage.removeItem(k));
  let keys = Object.keys(response?.data?.samples);
  localStorage.setItem("pdfList", JSON.stringify(keys));
  localStorage.setItem(
    "apiPreprocessRes",
    LZString.compress(JSON.stringify(response?.data?.samples))
  );
};
