/* eslint-disable */
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Template from "./Components/Forms/Template";
import Check from "./Components/Forms/Check";
import Image from "./Image";
import Home from "./Components/Pages/Home";
import CreateTemplate from "./Components/CreateTemplate/CreateTemplate";
import Upload from "./Components/Pages/Upload";
import Tab from "./Components/Dashboard/Tab";
import Dashboard from "./Components/Dashboard/Dashboard";
import axios from "axios";
import env from "./environment";
const CryptoJS = require("crypto-js");

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticatedUser: localStorage.getItem("authenticatedUser"),
    };
  }
  componentDidMount() {
    //calling refresh token api after every 115 minutes
    // this.intervalId = setInterval(() => this.loadData(), 6900000);
  }

  // loadData() {
  //   const header = {
  //     Authorization: `Bearer ${JSON.parse(
  //       localStorage.getItem("refreshToken")
  //     )}`,
  //     // "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MjczNjc0ODYsIm5iZiI6MTYyNzM2NzQ4NiwianRpIjoiN2Q1NWMwMzAtZDFmOC00NTEwLWJjMWQtMzg3YmIwN2RkNjg2IiwiaWRlbnRpdHkiOiJBZG1pbiIsImZyZXNoIjpmYWxzZSwidHlwZSI6ImFjY2VzcyJ9.BC_DftBQR8f0uExb7JyP6wdxuxZmpFmFIv0ow9n9XkE",
  //   };
  //   axios
  //     .post(`${env.BASE_URL}refreshtoken`, {}, {
  //       headers: header,
  //     })
  //     .then((res) => {
  //       localStorage.setItem("accessToken", JSON.stringify(res.data.access_token))
  //       localStorage.setItem("refreshToken", JSON.stringify(res.data.refresh_token))
  //     })
  // }

  render() {
    const { match } = this.props;
    let decryptedData = true;
    if (this.state.authenticatedUser) {
      const bytes = CryptoJS.AES.decrypt(
        this.state.authenticatedUser,
        "my-secret-key@123"
      );
      decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      console.log(decryptedData);
    } else {
      decryptedData = false;
    }
    return (
      <>
        <div className="">
          <Switch>
            {decryptedData === true ? (
              <Route
                exact
                path={`${match.path}home`}
                render={(props) => <Tab {...props} />}
              />
            ) : (
              <Redirect to="/restrict" />
            )}

            {decryptedData === true ? (
              <Route
                exact
                path={`${match.path}form`}
                render={(props) => <Home {...props} />}
              />
            ) : (
              <Redirect to="/restrict" />
            )}

            {decryptedData === true ? (
              <Route
                exact
                path={`${match.path}check`}
                render={(props) => <Check {...props} />}
              />
            ) : (
              <Redirect to="/restrict" />
            )}

            {decryptedData === true ? (
              <Route
                exact
                path={`${match.path}image`}
                render={(props) => <Image {...props} />}
              />
            ) : (
              <Redirect to="/restrict" />
            )}

            {decryptedData === true ? (
              <Route
                exact
                path={`${match.path}template`}
                render={(props) => <Template {...props} />}
              />
            ) : (
              <Redirect to="/restrict" />
            )}

            {decryptedData === true ? (
              <Route
                exact
                path={`${match.path}create`}
                render={(props) => <CreateTemplate {...props} />}
              />
            ) : (
              <Redirect to="/restrict" />
            )}

            {decryptedData === true ? (
              <Route
                exact
                path={`${match.path}upload`}
                render={(props) => <Upload {...props} />}
              />
            ) : (
              <Redirect to="/restrict" />
            )}
          </Switch>
        </div>
      </>
    );
  }
}

export default Layout;
