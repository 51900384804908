/* eslint-disable no-useless-escape */
import axios from "axios";
import env from "./environment";
export const data = {
  DocumentMetadata: {
    Pages: 16,
  },
  Blocks: [
    {
      BlockType: "LINE",
      Confidence: 98.2123031616211,
      Text: "CHECK NUMBER",
      Geometry: {
        BoundingBox: {
          Width: 0.08321880549192429,
          Height: 0.023050475865602493,
          Left: 0.7963849306106567,
          Top: 0.03180275112390518,
        },
        Polygon: [
          {
            X: 0.7963849306106567,
            Y: 0.03180275112390518,
          },
          {
            X: 0.8796037435531616,
            Y: 0.03180275112390518,
          },
          {
            X: 0.8796037435531616,
            Y: 0.05485322326421738,
          },
          {
            X: 0.7963849306106567,
            Y: 0.05485322326421738,
          },
        ],
      },
      Id: "de28ea54-92ad-43b1-af97-0ac80b408ed1",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "c871ff03-13b0-46e3-a6f6-db34307c1384",
            "c95537c6-fc6e-410e-8a32-7aaefa8741a8",
          ],
        },
      ],
      Page: 1,
    },
    {
      BlockType: "LINE",
      Confidence: 99.86421203613281,
      Text: "Amerigroup",
      Geometry: {
        BoundingBox: {
          Width: 0.11658947914838791,
          Height: 0.04774896055459976,
          Left: 0.08330769091844559,
          Top: 0.051063381135463715,
        },
        Polygon: [
          {
            X: 0.08330769091844559,
            Y: 0.051063381135463715,
          },
          {
            X: 0.1998971700668335,
            Y: 0.051063381135463715,
          },
          {
            X: 0.1998971700668335,
            Y: 0.09881234169006348,
          },
          {
            X: 0.08330769091844559,
            Y: 0.09881234169006348,
          },
        ],
      },
      Id: "eb3d8ec5-93a0-45fb-ab83-185949236c00",
      Relationships: [
        {
          Type: "CHILD",
          Ids: ["7e424545-6d4b-4e9a-8378-9163897f2d5c"],
        },
      ],
      Page: 1,
    },
    {
      BlockType: "LINE",
      Confidence: 94.37715148925781,
      Text: "AMGP GEORGIA MANAGED CARE CO INC",
      Geometry: {
        BoundingBox: {
          Width: 0.20634084939956665,
          Height: 0.023819057270884514,
          Left: 0.28673115372657776,
          Top: 0.04883129894733429,
        },
        Polygon: [
          {
            X: 0.28673115372657776,
            Y: 0.04883129894733429,
          },
          {
            X: 0.4930720031261444,
            Y: 0.04883129894733429,
          },
          {
            X: 0.4930720031261444,
            Y: 0.07265035808086395,
          },
          {
            X: 0.28673115372657776,
            Y: 0.07265035808086395,
          },
        ],
      },
      Id: "06ac39c9-ce20-471a-b434-eb0973929f1a",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "c0d6c84c-b3a7-44c9-9850-912152bde103",
            "cd945105-f99f-4f0c-9a5f-45062184b90a",
            "9091444a-6ea7-400a-a27a-bbd12b491201",
            "4d8762a2-169c-48a7-90c9-97d518fb16d6",
            "af949bb5-25eb-4271-9a8c-381e3f7aa460",
            "fd97b492-c0bc-4697-bab3-be55373d95e9",
          ],
        },
      ],
      Page: 1,
    },
    {
      BlockType: "LINE",
      Confidence: 99.59010314941406,
      Text: "BANK OF AMERICA",
      Geometry: {
        BoundingBox: {
          Width: 0.09777682274580002,
          Height: 0.023262869566679,
          Left: 0.553458034992218,
          Top: 0.048695240169763565,
        },
        Polygon: [
          {
            X: 0.553458034992218,
            Y: 0.048695240169763565,
          },
          {
            X: 0.6512348651885986,
            Y: 0.048695240169763565,
          },
          {
            X: 0.6512348651885986,
            Y: 0.07195810973644257,
          },
          {
            X: 0.553458034992218,
            Y: 0.07195810973644257,
          },
        ],
      },
      Id: "2dd1b47a-7bff-4d79-9512-c884a73ecd50",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "b11cbbda-178d-44b5-af3e-6e65f2b2b44d",
            "3928f099-f7d4-4e5f-831c-a6bef0fbc96a",
            "c1046627-140f-4640-9d0c-7de76cfac1e0",
          ],
        },
      ],
      Page: 1,
    },
    {
      BlockType: "LINE",
      Confidence: 83.2450180053711,
      Text: "PO BOX 7368 GA081E001LE",
      Geometry: {
        BoundingBox: {
          Width: 0.15593719482421875,
          Height: 0.022877685725688934,
          Left: 0.28654760122299194,
          Top: 0.0805165097117424,
        },
        Polygon: [
          {
            X: 0.28654760122299194,
            Y: 0.0805165097117424,
          },
          {
            X: 0.4424847960472107,
            Y: 0.0805165097117424,
          },
          {
            X: 0.4424847960472107,
            Y: 0.10339419543743134,
          },
          {
            X: 0.28654760122299194,
            Y: 0.10339419543743134,
          },
        ],
      },
      Id: "68c11350-26ac-465c-b2fe-7d8ecbfd35a0",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "16260755-d511-4b9a-b39b-d511609ee5d8",
            "8dfb0591-02b6-4101-9200-915ae32d4a05",
            "1fb21ccb-e48a-4c71-81f5-60329efca517",
            "0738309d-3ec8-49a9-bb93-e9283d94f645",
          ],
        },
      ],
      Page: 1,
    },
    {
      BlockType: "LINE",
      Confidence: 99.23455810546875,
      Text: "ATLANTA GEORGIA",
      Geometry: {
        BoundingBox: {
          Width: 0.10518872737884521,
          Height: 0.024716097861528397,
          Left: 0.5532614588737488,
          Top: 0.07953771948814392,
        },
        Polygon: [
          {
            X: 0.5532614588737488,
            Y: 0.07953771948814392,
          },
          {
            X: 0.658450186252594,
            Y: 0.07953771948814392,
          },
          {
            X: 0.658450186252594,
            Y: 0.10425381362438202,
          },
          {
            X: 0.5532614588737488,
            Y: 0.10425381362438202,
          },
        ],
      },
      Id: "e2dc6a22-272e-49bb-98ee-96847ddc7876",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "223e0f3a-1ff1-40fa-8cfb-5e294b671799",
            "3e612ea4-dd96-4224-8333-078650059136",
          ],
        },
      ],
      Page: 1,
    },
    {
      BlockType: "LINE",
      Confidence: 99.65348052978516,
      Text: "0100112237",
      Geometry: {
        BoundingBox: {
          Width: 0.12413477897644043,
          Height: 0.04417763277888298,
          Left: 0.7797530889511108,
          Top: 0.05755283311009407,
        },
        Polygon: [
          {
            X: 0.7797530889511108,
            Y: 0.05755283311009407,
          },
          {
            X: 0.9038878679275513,
            Y: 0.05755283311009407,
          },
          {
            X: 0.9038878679275513,
            Y: 0.10173046588897705,
          },
          {
            X: 0.7797530889511108,
            Y: 0.10173046588897705,
          },
        ],
      },
      Id: "a38338a3-d013-4b85-885e-bab9185e33e3",
      Relationships: [
        {
          Type: "CHILD",
          Ids: ["8909399c-aea1-4394-bbbe-608b71edab3f"],
        },
      ],
      Page: 1,
    },
    {
      BlockType: "LINE",
      Confidence: 91.4392318725586,
      Text: "RealSolutions",
      Geometry: {
        BoundingBox: {
          Width: 0.12751007080078125,
          Height: 0.04065942019224167,
          Left: 0.08497700840234756,
          Top: 0.10028668493032455,
        },
        Polygon: [
          {
            X: 0.08497700840234756,
            Y: 0.10028668493032455,
          },
          {
            X: 0.2124870866537094,
            Y: 0.10028668493032455,
          },
          {
            X: 0.2124870866537094,
            Y: 0.14094610512256622,
          },
          {
            X: 0.08497700840234756,
            Y: 0.14094610512256622,
          },
        ],
      },
      Id: "d456f4eb-2088-4d27-8be6-7e11e4cb6939",
      Relationships: [
        {
          Type: "CHILD",
          Ids: ["fa49476f-40ae-46c4-9c6d-57f6d4641eb0"],
        },
      ],
      Page: 1,
    },
    {
      BlockType: "LINE",
      Confidence: 95.8073501586914,
      Text: "COLUMBUS, GA 31908-7368",
      Geometry: {
        BoundingBox: {
          Width: 0.14409677684307098,
          Height: 0.0269531961530447,
          Left: 0.2873234152793884,
          Top: 0.11036384850740433,
        },
        Polygon: [
          {
            X: 0.2873234152793884,
            Y: 0.11036384850740433,
          },
          {
            X: 0.4314202070236206,
            Y: 0.11036384850740433,
          },
          {
            X: 0.4314202070236206,
            Y: 0.13731704652309418,
          },
          {
            X: 0.2873234152793884,
            Y: 0.13731704652309418,
          },
        ],
      },
      Id: "fb4afb79-4f01-4a77-9e4f-72ed99e6f03a",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "0a0b1d19-477c-4a01-8747-f9a69b4d6658",
            "bd3c06f0-ce9a-40aa-ad78-0c1263fdee15",
            "f55267ba-1324-4dd4-a9b9-a73bff995e10",
          ],
        },
      ],
      Page: 1,
    },
    {
      BlockType: "LINE",
      Confidence: 83.16553497314453,
      Text: "00641127870611",
      Geometry: {
        BoundingBox: {
          Width: 0.09312111139297485,
          Height: 0.022678956389427185,
          Left: 0.7955601215362549,
          Top: 0.109270378947258,
        },
        Polygon: [
          {
            X: 0.7955601215362549,
            Y: 0.109270378947258,
          },
          {
            X: 0.8886812329292297,
            Y: 0.109270378947258,
          },
          {
            X: 0.8886812329292297,
            Y: 0.13194933533668518,
          },
          {
            X: 0.7955601215362549,
            Y: 0.13194933533668518,
          },
        ],
      },
      Id: "ca570302-9ffa-4147-9557-5d9660084d87",
      Relationships: [
        {
          Type: "CHILD",
          Ids: ["d2a5ec4d-d2b9-4673-b67c-497d15fd6d00"],
        },
      ],
      Page: 1,
    },
    {
      BlockType: "LINE",
      Confidence: 95.03250885009766,
      Text: "in healthcare",
      Geometry: {
        BoundingBox: {
          Width: 0.06193440034985542,
          Height: 0.023125823587179184,
          Left: 0.08399063348770142,
          Top: 0.14683246612548828,
        },
        Polygon: [
          {
            X: 0.08399063348770142,
            Y: 0.14683246612548828,
          },
          {
            X: 0.14592503011226654,
            Y: 0.14683246612548828,
          },
          {
            X: 0.14592503011226654,
            Y: 0.16995829343795776,
          },
          {
            X: 0.08399063348770142,
            Y: 0.16995829343795776,
          },
        ],
      },
      Id: "85d411bb-5445-42a4-812a-c7a7ddd21393",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "86002706-1b71-4a51-86c4-69ce5dc18083",
            "82a73a90-a49d-4a0e-9670-2693a08675e6",
          ],
        },
      ],
      Page: 1,
    },
    {
      BlockType: "LINE",
      Confidence: 77.83714294433594,
      Text: "0219A11606578.0019",
      Geometry: {
        BoundingBox: {
          Width: 0.1057555079460144,
          Height: 0.023203611373901367,
          Left: 0.55763840675354,
          Top: 0.15169574320316315,
        },
        Polygon: [
          {
            X: 0.55763840675354,
            Y: 0.15169574320316315,
          },
          {
            X: 0.6633939146995544,
            Y: 0.15169574320316315,
          },
          {
            X: 0.6633939146995544,
            Y: 0.17489935457706451,
          },
          {
            X: 0.55763840675354,
            Y: 0.17489935457706451,
          },
        ],
      },
      Id: "d39c5c78-6dc2-43a9-b752-a1760097a3f6",
      Relationships: [
        {
          Type: "CHILD",
          Ids: ["252d1d66-608b-427e-9bf2-a61041704e65"],
        },
      ],
      Page: 1,
    },
    {
      BlockType: "LINE",
      Confidence: 94.269775390625,
      Text: "C000134",
      Geometry: {
        BoundingBox: {
          Width: 0.042727887630462646,
          Height: 0.022492334246635437,
          Left: 0.7086099982261658,
          Top: 0.15608179569244385,
        },
        Polygon: [
          {
            X: 0.7086099982261658,
            Y: 0.15608179569244385,
          },
          {
            X: 0.7513378858566284,
            Y: 0.15608179569244385,
          },
          {
            X: 0.7513378858566284,
            Y: 0.17857412993907928,
          },
          {
            X: 0.7086099982261658,
            Y: 0.17857412993907928,
          },
        ],
      },
      Id: "904cb5bb-22d5-4c2e-8fed-9a6dfeceff5b",
      Relationships: [
        {
          Type: "CHILD",
          Ids: ["31da3f24-27c0-4a5f-91a0-b4c43e83a9ec"],
        },
      ],
      Page: 1,
    },
    {
      BlockType: "LINE",
      Confidence: 96.3075180053711,
      Text: "3359874289",
      Geometry: {
        BoundingBox: {
          Width: 0.08190053701400757,
          Height: 0.02611704170703888,
          Left: 0.7974256873130798,
          Top: 0.15300911664962769,
        },
        Polygon: [
          {
            X: 0.7974256873130798,
            Y: 0.15300911664962769,
          },
          {
            X: 0.8793262243270874,
            Y: 0.15300911664962769,
          },
          {
            X: 0.8793262243270874,
            Y: 0.17912615835666656,
          },
          {
            X: 0.7974256873130798,
            Y: 0.17912615835666656,
          },
        ],
      },
      Id: "e6feed7b-886f-4c9f-9a10-6cd3bf019d2c",
      Relationships: [
        {
          Type: "CHILD",
          Ids: ["de51190b-f6a6-4848-a799-6d60dd7b4121"],
        },
      ],
      Page: 1,
    },
    {
      BlockType: "LINE",
      Confidence: 88.87210845947266,
      Text: "PROVIDEN; ID NO.",
      Geometry: {
        BoundingBox: {
          Width: 0.08070497959852219,
          Height: 0.022788193076848984,
          Left: 0.2634696960449219,
          Top: 0.19614237546920776,
        },
        Polygon: [
          {
            X: 0.2634696960449219,
            Y: 0.19614237546920776,
          },
          {
            X: 0.34417468309402466,
            Y: 0.19614237546920776,
          },
          {
            X: 0.34417468309402466,
            Y: 0.21893057227134705,
          },
          {
            X: 0.2634696960449219,
            Y: 0.21893057227134705,
          },
        ],
      },
      Id: "3d6c36d0-45e8-4874-94b3-d6cc72a3e384",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "f2e7a4c1-91df-4dc3-92bb-d2a056a44b4d",
            "b8836042-a651-4b65-89ad-38e8f32d6bd3",
            "4c4a83a1-92be-44fc-a247-3de39fb995a2",
          ],
        },
      ],
      Page: 1,
    },
    {
      BlockType: "LINE",
      Confidence: 97.0103988647461,
      Text: "TAX ID NO",
      Geometry: {
        BoundingBox: {
          Width: 0.051803406327962875,
          Height: 0.0231935977935791,
          Left: 0.4630191922187805,
          Top: 0.1949232518672943,
        },
        Polygon: [
          {
            X: 0.4630191922187805,
            Y: 0.1949232518672943,
          },
          {
            X: 0.5148226022720337,
            Y: 0.1949232518672943,
          },
          {
            X: 0.5148226022720337,
            Y: 0.2181168496608734,
          },
          {
            X: 0.4630191922187805,
            Y: 0.2181168496608734,
          },
        ],
      },
      Id: "5b37ec59-a6de-4118-948f-9af3f76a7683",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "f7094164-b64a-42fc-969f-444c6ab7f152",
            "f9ce41ed-6dc1-4042-8755-3d7247460dae",
            "e468f322-c734-49a5-a83d-987d39e4677d",
          ],
        },
      ],
      Page: 1,
    },
    {
      BlockType: "LINE",
      Confidence: 94.75513458251953,
      Text: "DATE",
      Geometry: {
        BoundingBox: {
          Width: 0.027272522449493408,
          Height: 0.02252781391143799,
          Left: 0.6294510364532471,
          Top: 0.19550761580467224,
        },
        Polygon: [
          {
            X: 0.6294510364532471,
            Y: 0.19550761580467224,
          },
          {
            X: 0.6567235589027405,
            Y: 0.19550761580467224,
          },
          {
            X: 0.6567235589027405,
            Y: 0.21803542971611023,
          },
          {
            X: 0.6294510364532471,
            Y: 0.21803542971611023,
          },
        ],
      },
      Id: "2bef56ce-e47a-4fe0-ad46-16a58ff38ea9",
      Relationships: [
        {
          Type: "CHILD",
          Ids: ["1f80d684-1613-4746-862f-ffafe513e1d1"],
        },
      ],
      Page: 1,
    },
    {
      BlockType: "LINE",
      Confidence: 97.8978042602539,
      Text: "CHECK AMOUNT",
      Geometry: {
        BoundingBox: {
          Width: 0.08290410786867142,
          Height: 0.02301577292382717,
          Left: 0.772344708442688,
          Top: 0.19430126249790192,
        },
        Polygon: [
          {
            X: 0.772344708442688,
            Y: 0.19430126249790192,
          },
          {
            X: 0.8552488088607788,
            Y: 0.19430126249790192,
          },
          {
            X: 0.8552488088607788,
            Y: 0.21731702983379364,
          },
          {
            X: 0.772344708442688,
            Y: 0.21731702983379364,
          },
        ],
      },
      Id: "d790a93a-e771-4fea-b6da-22087949199f",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "8c12e9a7-74c2-4695-ae9d-8a5f29de5716",
            "6573e1fe-fc34-4264-84b1-77997cd571ec",
          ],
        },
      ],
      Page: 1,
    },
    {
      BlockType: "LINE",
      Confidence: 88.64126586914062,
      Text: "0209660E",
      Geometry: {
        BoundingBox: {
          Width: 0.07938669621944427,
          Height: 0.02979360520839691,
          Left: 0.23408807814121246,
          Top: 0.24024273455142975,
        },
        Polygon: [
          {
            X: 0.23408807814121246,
            Y: 0.24024273455142975,
          },
          {
            X: 0.31347477436065674,
            Y: 0.24024273455142975,
          },
          {
            X: 0.31347477436065674,
            Y: 0.27003633975982666,
          },
          {
            X: 0.23408807814121246,
            Y: 0.27003633975982666,
          },
        ],
      },
      Id: "2438064c-62c8-4035-927f-cfa5eaaf7e56",
      Relationships: [
        {
          Type: "CHILD",
          Ids: ["c1f684ad-196d-4abb-834b-b13bd061b184"],
        },
      ],
      Page: 1,
    },
    {
      BlockType: "LINE",
      Confidence: 90.414794921875,
      Text: ")XXXX0874",
      Geometry: {
        BoundingBox: {
          Width: 0.08971840143203735,
          Height: 0.03185306489467621,
          Left: 0.4303264021873474,
          Top: 0.239466592669487,
        },
        Polygon: [
          {
            X: 0.4303264021873474,
            Y: 0.239466592669487,
          },
          {
            X: 0.5200448036193848,
            Y: 0.239466592669487,
          },
          {
            X: 0.5200448036193848,
            Y: 0.2713196575641632,
          },
          {
            X: 0.4303264021873474,
            Y: 0.2713196575641632,
          },
        ],
      },
      Id: "6fe3bca4-8631-4ba3-a792-8c46a35d1ec0",
      Relationships: [
        {
          Type: "CHILD",
          Ids: ["dff497d0-9aa8-48bc-a701-131131ba62e2"],
        },
      ],
      Page: 1,
    },
    {
      BlockType: "LINE",
      Confidence: 81.68689727783203,
      Text: "02719120",
      Geometry: {
        BoundingBox: {
          Width: 0.08144259452819824,
          Height: 0.02985803782939911,
          Left: 0.606840968132019,
          Top: 0.24012236297130585,
        },
        Polygon: [
          {
            X: 0.606840968132019,
            Y: 0.24012236297130585,
          },
          {
            X: 0.6882835626602173,
            Y: 0.24012236297130585,
          },
          {
            X: 0.6882835626602173,
            Y: 0.26998040080070496,
          },
          {
            X: 0.606840968132019,
            Y: 0.26998040080070496,
          },
        ],
      },
      Id: "7c5a0158-9613-46f4-901e-2d6b07b12301",
      Relationships: [
        {
          Type: "CHILD",
          Ids: ["52b98638-5a88-4cdb-aa95-f700454ee5f5"],
        },
      ],
      Page: 1,
    },
    {
      BlockType: "LINE",
      Confidence: 71.80618286132812,
      Text: "$xxx5711. 42",
      Geometry: {
        BoundingBox: {
          Width: 0.16931535303592682,
          Height: 0.034102410078048706,
          Left: 0.7419938445091248,
          Top: 0.28390464186668396,
        },
        Polygon: [
          {
            X: 0.7419938445091248,
            Y: 0.28390464186668396,
          },
          {
            X: 0.9113091826438904,
            Y: 0.28390464186668396,
          },
          {
            X: 0.9113091826438904,
            Y: 0.31800705194473267,
          },
          {
            X: 0.7419938445091248,
            Y: 0.31800705194473267,
          },
        ],
      },
      Id: "6edd6f06-b81c-43f8-a70b-899281f9e8e2",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "d7343339-8cca-435a-a13b-0e134f5235d2",
            "b699800d-d8b3-49d2-8d85-1d1a40bdd8db",
          ],
        },
      ],
      Page: 1,
    },
    {
      BlockType: "LINE",
      Confidence: 89.339111328125,
      Text: "X***X**XXXFIVE FIVE THOUSAND SEVEN HUNDRED ELEVEN 42/10.0 DOLLARS",
      Geometry: {
        BoundingBox: {
          Width: 0.46365711092948914,
          Height: 0.027584610506892204,
          Left: 0.4620189964771271,
          Top: 0.382881760597229,
        },
        Polygon: [
          {
            X: 0.4620189964771271,
            Y: 0.382881760597229,
          },
          {
            X: 0.9256761074066162,
            Y: 0.382881760597229,
          },
          {
            X: 0.9256761074066162,
            Y: 0.41046637296676636,
          },
          {
            X: 0.4620189964771271,
            Y: 0.41046637296676636,
          },
        ],
      },
      Id: "f3eeca0e-8ff2-42e1-a509-06c606f0951f",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "9103ce80-4087-4220-93ef-f8164959b171",
            "e25e0223-466e-4e55-aa7d-45931916b01c",
            "ca82528b-0a00-4cee-8bff-7ae0cf8d5242",
            "861bad0c-8aad-4e5d-95de-360c57083365",
            "6c2e9f12-204b-45bc-a40b-d9cad4192006",
            "45dca6c4-ea19-400e-9f11-e3150448a4b3",
            "8b3f4ece-449c-4ff3-9e15-5e01ffda367f",
            "70166863-4f70-4ffd-8dc5-ccc6c12a6da3",
          ],
        },
      ],
      Page: 1,
    },
    {
      BlockType: "LINE",
      Confidence: 87.20813751220703,
      Text: "TO THE ORDER OF",
      Geometry: {
        BoundingBox: {
          Width: 0.09354575723409653,
          Height: 0.022191226482391357,
          Left: 0.1813361644744873,
          Top: 0.4425240755081177,
        },
        Polygon: [
          {
            X: 0.1813361644744873,
            Y: 0.4425240755081177,
          },
          {
            X: 0.27488192915916443,
            Y: 0.4425240755081177,
          },
          {
            X: 0.27488192915916443,
            Y: 0.46471530199050903,
          },
          {
            X: 0.1813361644744873,
            Y: 0.46471530199050903,
          },
        ],
      },
      Id: "faf9bb63-56ed-406d-afac-666e77d78fd2",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "54fdd31d-f076-4598-8c39-1b132510479c",
            "cc45bd77-5d6b-4784-8c43-344b6dc5d6ca",
            "e6e21706-f38c-41f8-aea0-104bc5d81188",
            "5e32955f-a2c3-4881-9019-8dad284c051c",
          ],
        },
      ],
      Page: 1,
    },
    {
      BlockType: "LINE",
      Confidence: 98.79476928710938,
      Text: "PIEDMONT PROVIDERS LLC",
      Geometry: {
        BoundingBox: {
          Width: 0.2163725346326828,
          Height: 0.03229008615016937,
          Left: 0.17068330943584442,
          Top: 0.5915390253067017,
        },
        Polygon: [
          {
            X: 0.17068330943584442,
            Y: 0.5915390253067017,
          },
          {
            X: 0.3870558440685272,
            Y: 0.5915390253067017,
          },
          {
            X: 0.3870558440685272,
            Y: 0.6238290667533875,
          },
          {
            X: 0.17068330943584442,
            Y: 0.6238290667533875,
          },
        ],
      },
      Id: "d61dfd84-3a67-4032-9e6a-d0e309e9c0f2",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "a41a17b2-ee60-4605-9c28-9fadfe264d7f",
            "18eeaf47-c309-4dc3-ac28-a621dcaed247",
            "b6172558-140c-4821-9aa1-41eafc0c7211",
          ],
        },
      ],
      Page: 1,
    },
    {
      BlockType: "LINE",
      Confidence: 99.58183288574219,
      Text: "PO BOX 102321",
      Geometry: {
        BoundingBox: {
          Width: 0.12980683147907257,
          Height: 0.030968666076660156,
          Left: 0.17047254741191864,
          Top: 0.6283293962478638,
        },
        Polygon: [
          {
            X: 0.17047254741191864,
            Y: 0.6283293962478638,
          },
          {
            X: 0.3002793788909912,
            Y: 0.6283293962478638,
          },
          {
            X: 0.3002793788909912,
            Y: 0.6592980623245239,
          },
          {
            X: 0.17047254741191864,
            Y: 0.6592980623245239,
          },
        ],
      },
      Id: "de3b27a3-b778-4ebf-82fe-497c4edcb11a",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "59aefb2b-0702-454c-b80d-7729ba5b4295",
            "9c35c174-e3b0-4074-b550-3c313bcc3bd9",
            "0ba1cc78-3597-404c-b0a9-ba7f26d97194",
          ],
        },
      ],
      Page: 1,
    },
    {
      BlockType: "LINE",
      Confidence: 37.897674560546875,
      Text: "me",
      Geometry: {
        BoundingBox: {
          Width: 0.14042001962661743,
          Height: 0.1631617546081543,
          Left: 0.5329452753067017,
          Top: 0.538425087928772,
        },
        Polygon: [
          {
            X: 0.5329452753067017,
            Y: 0.538425087928772,
          },
          {
            X: 0.6733652949333191,
            Y: 0.538425087928772,
          },
          {
            X: 0.6733652949333191,
            Y: 0.7015868425369263,
          },
          {
            X: 0.5329452753067017,
            Y: 0.7015868425369263,
          },
        ],
      },
      Id: "7f65fe8c-c04d-44bb-8a3d-582086433a3e",
      Relationships: [
        {
          Type: "CHILD",
          Ids: ["bc305398-0067-4139-be08-33e626dbf2b7"],
        },
      ],
      Page: 1,
    },
    {
      BlockType: "LINE",
      Confidence: 42.34065628051758,
      Text: "ere",
      Geometry: {
        BoundingBox: {
          Width: 0.09573781490325928,
          Height: 0.08170604705810547,
          Left: 0.8262946605682373,
          Top: 0.5896894931793213,
        },
        Polygon: [
          {
            X: 0.8262946605682373,
            Y: 0.5896894931793213,
          },
          {
            X: 0.9220324754714966,
            Y: 0.5896894931793213,
          },
          {
            X: 0.9220324754714966,
            Y: 0.6713955402374268,
          },
          {
            X: 0.8262946605682373,
            Y: 0.6713955402374268,
          },
        ],
      },
      Id: "796a2fc4-ee59-48c2-80cd-3cbf35ea96b5",
      Relationships: [
        {
          Type: "CHILD",
          Ids: ["b9c6d1fe-2f1d-40f5-8b9e-048df598e4d3"],
        },
      ],
      Page: 1,
    },
    {
      BlockType: "LINE",
      Confidence: 98.07719421386719,
      Text: "ATLANTA GA 30368-2321",
      Geometry: {
        BoundingBox: {
          Width: 0.21471326053142548,
          Height: 0.031380876898765564,
          Left: 0.17055340111255646,
          Top: 0.6634138822555542,
        },
        Polygon: [
          {
            X: 0.17055340111255646,
            Y: 0.6634138822555542,
          },
          {
            X: 0.38526666164398193,
            Y: 0.6634138822555542,
          },
          {
            X: 0.38526666164398193,
            Y: 0.694794774055481,
          },
          {
            X: 0.17055340111255646,
            Y: 0.694794774055481,
          },
        ],
      },
      Id: "4536478f-34c7-4628-9808-33de2a647bcd",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "a3e2ca21-883c-41d4-9f49-10de4d6da064",
            "d2edb8d4-cc72-4d5f-834f-3b939caaa323",
            "51f76d5f-e139-40a5-86da-39205b390f48",
          ],
        },
      ],
      Page: 1,
    },
    {
      BlockType: "LINE",
      Confidence: 91.94800567626953,
      Text: "AMGP GEORGIA MANAGED CARE CO INC",
      Geometry: {
        BoundingBox: {
          Width: 0.2053002119064331,
          Height: 0.02391957677900791,
          Left: 0.7201392650604248,
          Top: 0.6842605471611023,
        },
        Polygon: [
          {
            X: 0.7201392650604248,
            Y: 0.6842605471611023,
          },
          {
            X: 0.9254394769668579,
            Y: 0.6842605471611023,
          },
          {
            X: 0.9254394769668579,
            Y: 0.7081801295280457,
          },
          {
            X: 0.7201392650604248,
            Y: 0.7081801295280457,
          },
        ],
      },
      Id: "911473d9-8780-4b8c-8741-e7742683a01e",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "bd3b09b1-b259-43e7-9549-5a56735f07ce",
            "3eb17277-43ca-482b-b10a-a8b8912b3a22",
            "fd515611-0d29-4191-be4f-8d42c6f7a59d",
            "da84dc4d-d7ab-4fdf-929f-3b01d6fbe9d1",
            "c7394b4f-5aaf-45fe-9b7f-c31313194f8b",
            "d38aa6b7-e3cf-46c0-bb2b-05946a658747",
          ],
        },
      ],
      Page: 1,
    },
    {
      BlockType: "LINE",
      Confidence: 99.61170959472656,
      Text: "Security features",
      Geometry: {
        BoundingBox: {
          Width: 0.06309857219457626,
          Height: 0.02392500638961792,
          Left: 0.9016178250312805,
          Top: 0.7399554252624512,
        },
        Polygon: [
          {
            X: 0.9016178250312805,
            Y: 0.7399554252624512,
          },
          {
            X: 0.964716374874115,
            Y: 0.7399554252624512,
          },
          {
            X: 0.964716374874115,
            Y: 0.7638804316520691,
          },
          {
            X: 0.9016178250312805,
            Y: 0.7638804316520691,
          },
        ],
      },
      Id: "4dee3519-19da-4a8c-92d5-d6ad5ed169e7",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "93901833-0781-4ffb-99b7-985f4437dd4c",
            "9be1c6e9-6629-4e34-85d7-6bd8317004bb",
          ],
        },
      ],
      Page: 1,
    },
    {
      BlockType: "LINE",
      Confidence: 95.18252563476562,
      Text: "included",
      Geometry: {
        BoundingBox: {
          Width: 0.0329098105430603,
          Height: 0.020341575145721436,
          Left: 0.9013567566871643,
          Top: 0.7634366750717163,
        },
        Polygon: [
          {
            X: 0.9013567566871643,
            Y: 0.7634366750717163,
          },
          {
            X: 0.9342665672302246,
            Y: 0.7634366750717163,
          },
          {
            X: 0.9342665672302246,
            Y: 0.7837782502174377,
          },
          {
            X: 0.9013567566871643,
            Y: 0.7837782502174377,
          },
        ],
      },
      Id: "1044be85-a79a-4bf6-b309-d4aab5ba517a",
      Relationships: [
        {
          Type: "CHILD",
          Ids: ["a55e13a5-9012-41dc-afd7-04defcff6e92"],
        },
      ],
      Page: 1,
    },
    {
      BlockType: "LINE",
      Confidence: 81.86817169189453,
      Text: "Details on hack,",
      Geometry: {
        BoundingBox: {
          Width: 0.05970344319939613,
          Height: 0.022899329662322998,
          Left: 0.9014692902565002,
          Top: 0.7844225764274597,
        },
        Polygon: [
          {
            X: 0.9014692902565002,
            Y: 0.7844225764274597,
          },
          {
            X: 0.9611727595329285,
            Y: 0.7844225764274597,
          },
          {
            X: 0.9611727595329285,
            Y: 0.8073219060897827,
          },
          {
            X: 0.9014692902565002,
            Y: 0.8073219060897827,
          },
        ],
      },
      Id: "9085932a-ef16-4e9e-b1f2-7dece3e2d2fa",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "e3a6b7aa-a56a-49f0-8ab7-c4c2e602ee05",
            "4a9f6cc1-c0fe-47df-ad56-eccf888a5fe2",
            "d1169326-21dd-4054-8e32-54e401d7ecfc",
          ],
        },
      ],
      Page: 1,
    },
    {
      BlockType: "LINE",
      Confidence: 79.13998413085938,
      Text: '0100112237" 10E11127881: 3359871,289',
      Geometry: {
        BoundingBox: {
          Width: 0.5456048250198364,
          Height: 0.04164446145296097,
          Left: 0.14089834690093994,
          Top: 0.8937450051307678,
        },
        Polygon: [
          {
            X: 0.14089834690093994,
            Y: 0.8937450051307678,
          },
          {
            X: 0.6865031719207764,
            Y: 0.8937450051307678,
          },
          {
            X: 0.6865031719207764,
            Y: 0.9353894591331482,
          },
          {
            X: 0.14089834690093994,
            Y: 0.9353894591331482,
          },
        ],
      },
      Id: "1b8397b3-331f-4105-84d0-76a361e5ecd6",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "714e036a-a10b-4508-8e12-6f34069cd00f",
            "1a347d81-020e-41e0-9598-c525ff6c4c5e",
            "369b9daa-b9c8-48fe-9db9-23f867be0efb",
          ],
        },
      ],
      Page: 1,
    },
  ],
};
export const data2 = {
  DocumentMetadata: {
    Pages: 16,
  },
  Blocks: [
    {
      BlockType: "LINE",
      Confidence: 99.73047637939453,
      Text: "02/19/20 0100112237",
      Geometry: {
        BoundingBox: {
          Width: 0.13252966105937958,
          Height: 0.015292355790734291,
          Left: 0.5153563618659973,
          Top: 0.0011448628501966596,
        },
        Polygon: [
          {
            X: 0.5153563618659973,
            Y: 0.003441297449171543,
          },
          {
            X: 0.6477012038230896,
            Y: 0.0011448628501966596,
          },
          {
            X: 0.6478860378265381,
            Y: 0.014140783809125423,
          },
          {
            X: 0.5155411958694458,
            Y: 0.016437219455838203,
          },
        ],
      },
      Id: "9cfb8edc-64e7-46ea-8a4c-af9ba8b4f508",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "0f1e9e69-7e87-49ad-8ec8-f52c12990641",
            "48543e0b-74b5-4b2c-84f8-fadac0d46f80",
          ],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 96.10416412353516,
      Text: "0219A1160657-001997",
      Geometry: {
        BoundingBox: {
          Width: 0.11971104145050049,
          Height: 0.014128556475043297,
          Left: 0.6969175934791565,
          Top: 0.0013061764184385538,
        },
        Polygon: [
          {
            X: 0.6969175934791565,
            Y: 0.0033804159611463547,
          },
          {
            X: 0.816457211971283,
            Y: 0.0013061764184385538,
          },
          {
            X: 0.816628634929657,
            Y: 0.013360493816435337,
          },
          {
            X: 0.6970890164375305,
            Y: 0.015434733591973782,
          },
        ],
      },
      Id: "2c641cd9-c39f-4d7b-9d5f-c4666ba4e5d0",
      Relationships: [
        {
          Type: "CHILD",
          Ids: ["48edf67e-154e-43da-aba6-120ea4cd1a8f"],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 99.94058990478516,
      Text: "Amerigroup",
      Geometry: {
        BoundingBox: {
          Width: 0.1176072284579277,
          Height: 0.025802532210946083,
          Left: 0.08525481075048447,
          Top: 0.02573460154235363,
        },
        Polygon: [
          {
            X: 0.08525481075048447,
            Y: 0.02776944637298584,
          },
          {
            X: 0.20252400636672974,
            Y: 0.02573460154235363,
          },
          {
            X: 0.20286203920841217,
            Y: 0.04950229078531265,
          },
          {
            X: 0.0855928361415863,
            Y: 0.05153713375329971,
          },
        ],
      },
      Id: "cc9ef10b-bba9-49dc-8a61-aed91232c67c",
      Relationships: [
        {
          Type: "CHILD",
          Ids: ["d5b12e4c-99ba-4e99-8e09-94e9b18f325c"],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 97.49224853515625,
      Text: "02412",
      Geometry: {
        BoundingBox: {
          Width: 0.03776777163147926,
          Height: 0.012722974643111229,
          Left: 0.919858455657959,
          Top: 0.010924335569143295,
        },
        Polygon: [
          {
            X: 0.919858455657959,
            Y: 0.0115766990929842,
          },
          {
            X: 0.9574545621871948,
            Y: 0.010924335569143295,
          },
          {
            X: 0.957626223564148,
            Y: 0.02299494482576847,
          },
          {
            X: 0.9200301170349121,
            Y: 0.023647310212254524,
          },
        ],
      },
      Id: "a062cfde-b23f-4a40-bef6-ec85fc62cecf",
      Relationships: [
        {
          Type: "CHILD",
          Ids: ["6332ae25-7ae3-47b1-a8de-ddf7dc7da65e"],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 94.29220581054688,
      Text: "AMGP GEOFGIA MANAGED CARE CO INC",
      Geometry: {
        BoundingBox: {
          Width: 0.2067408710718155,
          Height: 0.017619388177990913,
          Left: 0.2603817582130432,
          Top: 0.026406142860651016,
        },
        Polygon: [
          {
            X: 0.2603817582130432,
            Y: 0.029990026727318764,
          },
          {
            X: 0.4669229984283447,
            Y: 0.026406142860651016,
          },
          {
            X: 0.4671226143836975,
            Y: 0.04044164717197418,
          },
          {
            X: 0.260581374168396,
            Y: 0.04402552917599678,
          },
        ],
      },
      Id: "a2bdbeb3-ade3-43e6-99df-8156a6289c92",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "df19973e-7718-47d3-bd73-4ed8c1576249",
            "67477629-d516-45a1-a981-907c7e494283",
            "a73b0be0-f84f-4142-8705-aae34fcfd967",
            "7a07ed24-0717-44e5-9d5c-32ba04665f2d",
            "adcbff76-607f-4796-8703-8dbbed419a39",
            "cfbebf91-7c29-43ba-b3f6-130123bf47e3",
          ],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 94.70540618896484,
      Text: "RealSolutions",
      Geometry: {
        BoundingBox: {
          Width: 0.12997481226921082,
          Height: 0.022279515862464905,
          Left: 0.08574735373258591,
          Top: 0.047917064279317856,
        },
        Polygon: [
          {
            X: 0.08574735373258591,
            Y: 0.05016743391752243,
          },
          {
            X: 0.21543730795383453,
            Y: 0.047917064279317856,
          },
          {
            X: 0.21572217345237732,
            Y: 0.06794621795415878,
          },
          {
            X: 0.0860322117805481,
            Y: 0.07019658386707306,
          },
        ],
      },
      Id: "c0c6dd6f-a6f3-4117-b95e-4792d3b6f38c",
      Relationships: [
        {
          Type: "CHILD",
          Ids: ["d4a0dd54-0033-4074-84af-134c241c230c"],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 95.8200454711914,
      Text: "PO BOX 7368 GA081E-0014",
      Geometry: {
        BoundingBox: {
          Width: 0.15689964592456818,
          Height: 0.015499640256166458,
          Left: 0.26055648922920227,
          Top: 0.0422237366437912,
        },
        Polygon: [
          {
            X: 0.26055648922920227,
            Y: 0.04494309052824974,
          },
          {
            X: 0.4172743856906891,
            Y: 0.0422237366437912,
          },
          {
            X: 0.41745615005493164,
            Y: 0.055004023015499115,
          },
          {
            X: 0.2607382535934448,
            Y: 0.05772337689995766,
          },
        ],
      },
      Id: "563ddd20-548f-4476-bf5b-e96abdf93092",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "18e77c2e-835d-4893-8679-9ce027068f94",
            "8ad70476-bb05-4d2e-9728-861b643f1e50",
            "989320f5-1962-482f-acd0-d4e65e573d28",
            "98795bcd-0cec-406b-bb34-3f54c325ce93",
          ],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 98.22124481201172,
      Text: "COLUMBUS; GA 31908-7368",
      Geometry: {
        BoundingBox: {
          Width: 0.14826618134975433,
          Height: 0.015710821375250816,
          Left: 0.25971996784210205,
          Top: 0.05488349124789238,
        },
        Polygon: [
          {
            X: 0.25971996784210205,
            Y: 0.05745294690132141,
          },
          {
            X: 0.40779924392700195,
            Y: 0.05488349124789238,
          },
          {
            X: 0.4079861342906952,
            Y: 0.06802485138177872,
          },
          {
            X: 0.2599068582057953,
            Y: 0.07059431076049805,
          },
        ],
      },
      Id: "915963c4-ca0b-4303-b9da-2029ce59e26a",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "ebd51c92-73a6-4ae5-bcd4-d7df6c43a650",
            "27e95baf-9353-4d5e-a561-3a61390fefc0",
            "5fe2cc2e-e7dc-4b4c-8d57-bcc5d659cd50",
          ],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 98.91371154785156,
      Text: "in healthcare",
      Geometry: {
        BoundingBox: {
          Width: 0.062145914882421494,
          Height: 0.01268970500677824,
          Left: 0.08563659340143204,
          Top: 0.07010369002819061,
        },
        Polygon: [
          {
            X: 0.08563659340143204,
            Y: 0.07117917388677597,
          },
          {
            X: 0.14761732518672943,
            Y: 0.07010369002819061,
          },
          {
            X: 0.14778250455856323,
            Y: 0.08171791583299637,
          },
          {
            X: 0.08580177277326584,
            Y: 0.08279339969158173,
          },
        ],
      },
      Id: "940e0ab6-e89b-46d8-92e2-700d8872671c",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "ed41a90e-e233-4e0e-8a52-9da2fb60d316",
            "bbff5b8c-7d72-4406-b78a-cfefb2730088",
          ],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 97.62732696533203,
      Text: "1 of 13",
      Geometry: {
        BoundingBox: {
          Width: 0.05273449048399925,
          Height: 0.014354931190609932,
          Left: 0.8972381949424744,
          Top: 0.06175277754664421,
        },
        Polygon: [
          {
            X: 0.8972381949424744,
            Y: 0.06266450136899948,
          },
          {
            X: 0.9497814774513245,
            Y: 0.06175277754664421,
          },
          {
            X: 0.9499726891517639,
            Y: 0.07519598305225372,
          },
          {
            X: 0.8974294066429138,
            Y: 0.07610771059989929,
          },
        ],
      },
      Id: "41a896ab-1184-4a0f-a46c-21a8df8efd7f",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "b3c5a1a9-dc84-4942-be51-51a3b0bad289",
            "c9aa7414-e31f-41ca-aef0-839308e1c6d2",
            "dd91a9b2-5eea-426c-9915-029289549417",
          ],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 60.73430633544922,
      Text: "liillililllpplliplppilllniplillililjil liilllil jilllillilili'h",
      Geometry: {
        BoundingBox: {
          Width: 0.3343712389469147,
          Height: 0.03214519843459129,
          Left: 0.17726275324821472,
          Top: 0.2396063357591629,
        },
        Polygon: [
          {
            X: 0.17726275324821472,
            Y: 0.24540181457996368,
          },
          {
            X: 0.5112592577934265,
            Y: 0.2396063357591629,
          },
          {
            X: 0.5116339921951294,
            Y: 0.2659560441970825,
          },
          {
            X: 0.1776375025510788,
            Y: 0.2717515230178833,
          },
        ],
      },
      Id: "4d4057ed-7d41-4589-96a1-41b7b9c44abc",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "74b060c4-b6bd-4b00-8db5-a4d907984fab",
            "c64948c8-af6e-437e-9b40-613e59f11085",
            "9367bf6a-c5d7-4c5b-9cdb-f8d08472d46f",
          ],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 94.4430160522461,
      Text: "******ALL FOR AADC 303",
      Geometry: {
        BoundingBox: {
          Width: 0.22439467906951904,
          Height: 0.019034311175346375,
          Left: 0.2719838321208954,
          Top: 0.26954641938209534,
        },
        Polygon: [
          {
            X: 0.2719838321208954,
            Y: 0.27343636751174927,
          },
          {
            X: 0.49616312980651855,
            Y: 0.26954641938209534,
          },
          {
            X: 0.49637851119041443,
            Y: 0.284690797328949,
          },
          {
            X: 0.27219921350479126,
            Y: 0.2885807156562805,
          },
        ],
      },
      Id: "06c9672e-8f93-4955-9acf-dfccafd752c5",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "9c7462d3-c186-47f1-9539-15c627e25cb7",
            "1d3507bf-52be-405c-8490-f8dde8633bfc",
            "1ffea17c-2705-4f28-88c6-b5a612d7ee3f",
            "9f227cae-e132-4f71-aaaa-2255339e4a08",
          ],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 91.3270263671875,
      Text: '8573 2 AB 0..41"|',
      Geometry: {
        BoundingBox: {
          Width: 0.13679666817188263,
          Height: 0.017064817249774933,
          Left: 0.17916853725910187,
          Top: 0.28550422191619873,
        },
        Polygon: [
          {
            X: 0.17916853725910187,
            Y: 0.2878742814064026,
          },
          {
            X: 0.315756231546402,
            Y: 0.28550422191619873,
          },
          {
            X: 0.3159652054309845,
            Y: 0.3001990020275116,
          },
          {
            X: 0.17937752604484558,
            Y: 0.30256906151771545,
          },
        ],
      },
      Id: "4302f949-4c2c-40c9-a515-4b157be7e999",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "67e48ac2-3f0c-4442-b067-95d059d8dc5a",
            "be992949-bb92-4c30-b153-03984e64db08",
            "dcbd057b-14f3-475c-af41-54aa12290e6e",
            "0c957b50-7e81-473b-a042-f66637cf7df9",
          ],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 91.14791107177734,
      Text: "44",
      Geometry: {
        BoundingBox: {
          Width: 0.015871191397309303,
          Height: 0.013140500523149967,
          Left: 0.5117970705032349,
          Top: 0.2870996296405792,
        },
        Polygon: [
          {
            X: 0.5117970705032349,
            Y: 0.28737184405326843,
          },
          {
            X: 0.5274852514266968,
            Y: 0.2870996296405792,
          },
          {
            X: 0.5276682376861572,
            Y: 0.2999679148197174,
          },
          {
            X: 0.5119800567626953,
            Y: 0.3002401292324066,
          },
        ],
      },
      Id: "161ebc12-5212-43f0-ba16-c2bb27002fca",
      Relationships: [
        {
          Type: "CHILD",
          Ids: ["a7c978a1-8e1d-4ba5-ad92-dd67d350da35"],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 96.22991943359375,
      Text: "PIEDMONT. PROVIDERS LLC",
      Geometry: {
        BoundingBox: {
          Width: 0.17399299144744873,
          Height: 0.0180797316133976,
          Left: 0.1796887069940567,
          Top: 0.3011399805545807,
        },
        Polygon: [
          {
            X: 0.1796887069940567,
            Y: 0.3041553795337677,
          },
          {
            X: 0.3534674644470215,
            Y: 0.3011399805545807,
          },
          {
            X: 0.3536817133426666,
            Y: 0.31620433926582336,
          },
          {
            X: 0.17990295588970184,
            Y: 0.319219708442688,
          },
        ],
      },
      Id: "77232fa7-953f-4c61-8763-402a3a3e42aa",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "4d56d9b2-a21e-4c4d-a331-57f67df45103",
            "04699930-33db-4bc1-b356-99c085639d7e",
            "4795ad90-4eb4-486c-a658-c40fa167af6f",
          ],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 95.28158569335938,
      Text: "PO .BOX 102321",
      Geometry: {
        BoundingBox: {
          Width: 0.09013435244560242,
          Height: 0.015090555883944035,
          Left: 0.1792702078819275,
          Top: 0.31685036420822144,
        },
        Polygon: [
          {
            X: 0.1792702078819275,
            Y: 0.31841105222702026,
          },
          {
            X: 0.26921212673187256,
            Y: 0.31685036420822144,
          },
          {
            X: 0.2694045603275299,
            Y: 0.33038026094436646,
          },
          {
            X: 0.17946262657642365,
            Y: 0.3319409191608429,
          },
        ],
      },
      Id: "5e9f1ac0-f057-4c16-8ecc-85984777bf4d",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "5613b217-73c3-4662-93c6-9ac27676273e",
            "a58e6345-1d89-4110-a70a-7aaae4562956",
            "7502d915-dd7f-46ec-abfd-8361d49d24ef",
          ],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 98.0776596069336,
      Text: "ATLANTA GA 30368-2321",
      Geometry: {
        BoundingBox: {
          Width: 0.14950984716415405,
          Height: 0.016990503296256065,
          Left: 0.17792685329914093,
          Top: 0.33021050691604614,
        },
        Polygon: [
          {
            X: 0.17792685329914093,
            Y: 0.3328012228012085,
          },
          {
            X: 0.32723188400268555,
            Y: 0.33021050691604614,
          },
          {
            X: 0.3274366855621338,
            Y: 0.3446102738380432,
          },
          {
            X: 0.17813163995742798,
            Y: 0.34720098972320557,
          },
        ],
      },
      Id: "10a85daa-7afd-4663-b365-8cc1e1563acb",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "6a94388c-07a0-4191-88b5-439c7b072f22",
            "e5214a4c-b7eb-4b43-ab89-cf2a05e852e9",
            "c25377b8-fd24-4c2a-bb6f-5ffe488ba476",
          ],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 96.23186492919922,
      Text: "AMGP GEORGIA MANAGED CARE CO INC",
      Geometry: {
        BoundingBox: {
          Width: 0.20534346997737885,
          Height: 0.017202505841851234,
          Left: 0.06126079335808754,
          Top: 0.48750123381614685,
        },
        Polygon: [
          {
            X: 0.06126079335808754,
            Y: 0.4910609722137451,
          },
          {
            X: 0.266410231590271,
            Y: 0.48750123381614685,
          },
          {
            X: 0.2666042745113373,
            Y: 0.5011439919471741,
          },
          {
            X: 0.06145482137799263,
            Y: 0.5047037601470947,
          },
        ],
      },
      Id: "4cc62992-be4b-4ae5-a719-efa8f7e1a676",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "f771dc8d-c29a-49a5-8f42-c271cabe5930",
            "1718e07a-a369-45de-8157-23284274deda",
            "6262c74a-5c94-4a68-aff2-d4c931cbb774",
            "4642d705-ba6c-45a8-a63a-35d8c7e615a1",
            "266d4ccf-e9da-4607-9c49-c9f7f5884c64",
            "20922b78-9a33-47fe-bb98-b73e0849f225",
          ],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 99.6605453491211,
      Text: "CHECK NUMBER",
      Geometry: {
        BoundingBox: {
          Width: 0.07893457263708115,
          Height: 0.012942296452820301,
          Left: 0.4669649004936218,
          Top: 0.48828715085983276,
        },
        Polygon: [
          {
            X: 0.4669649004936218,
            Y: 0.48965397477149963,
          },
          {
            X: 0.5457348227500916,
            Y: 0.48828715085983276,
          },
          {
            X: 0.5458994507789612,
            Y: 0.4998626410961151,
          },
          {
            X: 0.46712952852249146,
            Y: 0.501229465007782,
          },
        ],
      },
      Id: "5301e2b2-96f1-423c-ac89-cc8bafcbdd67",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "e84c842e-26b5-46a1-967e-dad610a6b7c2",
            "3c7fddf2-5074-4b59-9310-de36db84c459",
          ],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 99.83445739746094,
      Text: "0100112237",
      Geometry: {
        BoundingBox: {
          Width: 0.06348859518766403,
          Height: 0.012895070016384125,
          Left: 0.5592935085296631,
          Top: 0.4896427392959595,
        },
        Polygon: [
          {
            X: 0.5592935085296631,
            Y: 0.490741491317749,
          },
          {
            X: 0.622614324092865,
            Y: 0.4896427392959595,
          },
          {
            X: 0.6227821111679077,
            Y: 0.501439094543457,
          },
          {
            X: 0.5594612956047058,
            Y: 0.5025378465652466,
          },
        ],
      },
      Id: "92c5881e-48e8-4871-bf06-9f4015398a95",
      Relationships: [
        {
          Type: "CHILD",
          Ids: ["0a872e0f-6be9-42a0-b4b7-11ce1c4f425d"],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 94.99164581298828,
      Text: "DATE 02/19120",
      Geometry: {
        BoundingBox: {
          Width: 0.08608607947826385,
          Height: 0.01455826684832573,
          Left: 0.7093355059623718,
          Top: 0.4882056415081024,
        },
        Polygon: [
          {
            X: 0.7093355059623718,
            Y: 0.489696204662323,
          },
          {
            X: 0.7952357530593872,
            Y: 0.4882056415081024,
          },
          {
            X: 0.7954216003417969,
            Y: 0.5012733936309814,
          },
          {
            X: 0.7095213532447815,
            Y: 0.5027639269828796,
          },
        ],
      },
      Id: "c6f2eeb4-ee36-4f09-9949-536e04b3a07b",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "41bf5c14-63cb-4bf1-b8c1-45d7938dd8d8",
            "d111573b-c7a9-4c98-9576-f77fb9aeccdf",
          ],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 99.893310546875,
      Text: "PROVIDER NAME",
      Geometry: {
        BoundingBox: {
          Width: 0.08252685517072678,
          Height: 0.013385431841015816,
          Left: 0.5622333884239197,
          Top: 0.5219604969024658,
        },
        Polygon: [
          {
            X: 0.5622333884239197,
            Y: 0.5233895182609558,
          },
          {
            X: 0.6445901989936829,
            Y: 0.5219604969024658,
          },
          {
            X: 0.644760251045227,
            Y: 0.5339168906211853,
          },
          {
            X: 0.5624034404754639,
            Y: 0.5353459119796753,
          },
        ],
      },
      Id: "eb59bc90-e6bb-4ee9-9458-bdc4eb071f93",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "34236133-e5d3-424d-8871-5763bccb85e5",
            "667c0e03-3497-4cef-ad58-d8b97e3701c6",
          ],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 98.86756896972656,
      Text: "PIEDMONT PROVIDERS LLC",
      Geometry: {
        BoundingBox: {
          Width: 0.13971257209777832,
          Height: 0.015063198283314705,
          Left: 0.6751205921173096,
          Top: 0.5205757021903992,
        },
        Polygon: [
          {
            X: 0.6751205921173096,
            Y: 0.5229968428611755,
          },
          {
            X: 0.8146533370018005,
            Y: 0.5205757021903992,
          },
          {
            X: 0.8148331642150879,
            Y: 0.53321772813797,
          },
          {
            X: 0.6753003597259521,
            Y: 0.5356388688087463,
          },
        ],
      },
      Id: "1ee9b867-a010-4d0d-b91c-877982aea32a",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "b7f8fc35-0a18-4238-84a0-b33f4dccec04",
            "a6b12778-7ccb-4ba6-a60b-24e53ce215a3",
            "b9475a3c-5dd4-4c54-bac5-30eee9fb02e7",
          ],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 91.6775894165039,
      Text: "ADDRESS",
      Geometry: {
        BoundingBox: {
          Width: 0.047472111880779266,
          Height: 0.011760233901441097,
          Left: 0.5609504580497742,
          Top: 0.542240560054779,
        },
        Polygon: [
          {
            X: 0.5609504580497742,
            Y: 0.5430616140365601,
          },
          {
            X: 0.6082669496536255,
            Y: 0.542240560054779,
          },
          {
            X: 0.608422577381134,
            Y: 0.5531798005104065,
          },
          {
            X: 0.5611060261726379,
            Y: 0.5540007948875427,
          },
        ],
      },
      Id: "75dbf196-aca3-402d-9e10-736d07dc1421",
      Relationships: [
        {
          Type: "CHILD",
          Ids: ["8bf90a56-8ba4-4d4e-ad4b-86adf684b9ab"],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 85.2662353515625,
      Text: "PO BOX 102321",
      Geometry: {
        BoundingBox: {
          Width: 0.08152409642934799,
          Height: 0.013203687034547329,
          Left: 0.6747620105743408,
          Top: 0.5374889373779297,
        },
        Polygon: [
          {
            X: 0.6747620105743408,
            Y: 0.53890061378479,
          },
          {
            X: 0.7561183571815491,
            Y: 0.5374889373779297,
          },
          {
            X: 0.756286084651947,
            Y: 0.5492809414863586,
          },
          {
            X: 0.674929678440094,
            Y: 0.550692617893219,
          },
        ],
      },
      Id: "115b61d2-b4d0-47a8-8547-5c3d2a836010",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "85a4269f-c07c-4e0e-ba49-7067b8486035",
            "163bdf57-fcef-4953-9b80-09602608df1a",
            "ea460826-0535-4ded-8b60-a2c9b3b5c83a",
          ],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 99.78752899169922,
      Text: "ATLANTA GA 30368-2321",
      Geometry: {
        BoundingBox: {
          Width: 0.13249170780181885,
          Height: 0.014463686384260654,
          Left: 0.6743308305740356,
          Top: 0.55314040184021,
        },
        Polygon: [
          {
            X: 0.6743308305740356,
            Y: 0.555436372756958,
          },
          {
            X: 0.8066494464874268,
            Y: 0.55314040184021,
          },
          {
            X: 0.8068225383758545,
            Y: 0.5653080940246582,
          },
          {
            X: 0.6745038628578186,
            Y: 0.567604124546051,
          },
        ],
      },
      Id: "30738789-920e-45d4-a51a-8476ba3ffec8",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "e9a842b8-f67e-4050-9237-f683ad41c1df",
            "9fed3c3d-355e-499d-8771-26842ee36af5",
            "16e1c60a-fd65-4b09-999f-d5cbcd4c728a",
          ],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 97.92011260986328,
      Text: "PROVIDER-NP| ID'S",
      Geometry: {
        BoundingBox: {
          Width: 0.0901758000254631,
          Height: 0.012944787740707397,
          Left: 0.5622255206108093,
          Top: 0.5875422954559326,
        },
        Polygon: [
          {
            X: 0.5622255206108093,
            Y: 0.589104175567627,
          },
          {
            X: 0.6522394418716431,
            Y: 0.5875422954559326,
          },
          {
            X: 0.652401328086853,
            Y: 0.5989251732826233,
          },
          {
            X: 0.5623874068260193,
            Y: 0.6004870533943176,
          },
        ],
      },
      Id: "6c675046-5f1c-405e-96c2-00824e326320",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "ed0d4f21-4637-4efc-9333-4e07daebc88c",
            "e44380f4-e9d7-445a-b5cd-2260ac0d3ade",
          ],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 93.19351959228516,
      Text: "D2096605",
      Geometry: {
        BoundingBox: {
          Width: 0.05004669725894928,
          Height: 0.011266490444540977,
          Left: 0.6759669780731201,
          Top: 0.5874063372612,
        },
        Polygon: [
          {
            X: 0.6759669780731201,
            Y: 0.5882721543312073,
          },
          {
            X: 0.7258657217025757,
            Y: 0.5874063372612,
          },
          {
            X: 0.7260136604309082,
            Y: 0.5978069305419922,
          },
          {
            X: 0.6761148571968079,
            Y: 0.5986728072166443,
          },
        ],
      },
      Id: "dc5b8e00-c355-4529-8c06-0cfaf9d46d6a",
      Relationships: [
        {
          Type: "CHILD",
          Ids: ["7863fb18-9b47-4aef-9ca4-69a9a048548b"],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 97.35134887695312,
      Text: "- 1912956046",
      Geometry: {
        BoundingBox: {
          Width: 0.08011046797037125,
          Height: 0.01282627321779728,
          Left: 0.7817442417144775,
          Top: 0.5864428877830505,
        },
        Polygon: [
          {
            X: 0.7817442417144775,
            Y: 0.587830126285553,
          },
          {
            X: 0.8616920709609985,
            Y: 0.5864428877830505,
          },
          {
            X: 0.8618547320365906,
            Y: 0.5978819131851196,
          },
          {
            X: 0.7819069623947144,
            Y: 0.5992691516876221,
          },
        ],
      },
      Id: "a0b6e213-cfc3-4e4e-91d4-63be9e4b540d",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "b8efecb4-7699-42c0-bf7b-e2e111f6ac82",
            "d24ef5b6-993d-40b9-afd3-3aea09461d09",
          ],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 98.2767333984375,
      Text: "TAX ID NO",
      Geometry: {
        BoundingBox: {
          Width: 0.05010827258229256,
          Height: 0.011756306514143944,
          Left: 0.5613301396369934,
          Top: 0.6040731072425842,
        },
        Polygon: [
          {
            X: 0.5613301396369934,
            Y: 0.604939877986908,
          },
          {
            X: 0.611283540725708,
            Y: 0.6040731072425842,
          },
          {
            X: 0.6114384531974792,
            Y: 0.6149625778198242,
          },
          {
            X: 0.5614850521087646,
            Y: 0.6158294081687927,
          },
        ],
      },
      Id: "293f9368-1133-41c8-b9bb-d25c2e7416b9",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "89672ddd-a0fb-4c52-8265-3d8f17722d58",
            "49f7dd7c-2a86-4df1-a5d9-035a298fa2a9",
            "acf01cae-c952-43d9-a1b7-5b4bf863597d",
          ],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 98.84618377685547,
      Text: "XXXXX0874",
      Geometry: {
        BoundingBox: {
          Width: 0.05826326459646225,
          Height: 0.011908945627510548,
          Left: 0.6754158735275269,
          Top: 0.6031361818313599,
        },
        Polygon: [
          {
            X: 0.6754158735275269,
            Y: 0.6041444540023804,
          },
          {
            X: 0.7335240840911865,
            Y: 0.6031361818313599,
          },
          {
            X: 0.7336791157722473,
            Y: 0.6140368580818176,
          },
          {
            X: 0.6755709052085876,
            Y: 0.6150451302528381,
          },
        ],
      },
      Id: "4061640d-ccfb-404c-a195-f39b7bf5746f",
      Relationships: [
        {
          Type: "CHILD",
          Ids: ["0c519ef5-beea-49d4-ad5e-9e744682f166"],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 99.91132354736328,
      Text: "PAYMENT SUMMARY",
      Geometry: {
        BoundingBox: {
          Width: 0.1021752581000328,
          Height: 0.013974434696137905,
          Left: 0.05671016499400139,
          Top: 0.6342374086380005,
        },
        Polygon: [
          {
            X: 0.05671016499400139,
            Y: 0.6360073089599609,
          },
          {
            X: 0.15871185064315796,
            Y: 0.6342374086380005,
          },
          {
            X: 0.1588854193687439,
            Y: 0.6464418768882751,
          },
          {
            X: 0.05688374117016792,
            Y: 0.6482118368148804,
          },
        ],
      },
      Id: "79cf5dd3-6adc-40a7-a7f0-2c125c1ac582",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "ee9c3fd3-8fed-4d87-a695-c740af7d73f5",
            "bba92105-20a9-4417-9ed4-d6b5fbc67a70",
          ],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 99.5545425415039,
      Text: "GROSS APPROVED CLAIM AMOUNT",
      Geometry: {
        BoundingBox: {
          Width: 0.1693567931652069,
          Height: 0.015442841686308384,
          Left: 0.07601315528154373,
          Top: 0.6533069014549255,
        },
        Polygon: [
          {
            X: 0.07601315528154373,
            Y: 0.6562424898147583,
          },
          {
            X: 0.24519206583499908,
            Y: 0.6533069014549255,
          },
          {
            X: 0.24536995589733124,
            Y: 0.6658141613006592,
          },
          {
            X: 0.07619103789329529,
            Y: 0.6687497496604919,
          },
        ],
      },
      Id: "baab7eae-e7b3-4778-a91d-ea2bda301333",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "02acc9af-0d48-4631-b8de-4fb222c9d332",
            "92d85f83-9792-4384-a21e-5172f78d4167",
            "2342bea9-4431-4697-a870-4271ecaf1d28",
            "bf4c12b6-b686-48ea-9a3b-471a65ce1a94",
          ],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 98.86840057373047,
      Text: "5,711.42",
      Geometry: {
        BoundingBox: {
          Width: 0.053924329578876495,
          Height: 0.013296704739332199,
          Left: 0.4169437289237976,
          Top: 0.651418149471283,
        },
        Polygon: [
          {
            X: 0.4169437289237976,
            Y: 0.6523507833480835,
          },
          {
            X: 0.4706922173500061,
            Y: 0.651418149471283,
          },
          {
            X: 0.4708680808544159,
            Y: 0.6637822389602661,
          },
          {
            X: 0.4171195924282074,
            Y: 0.6647148728370667,
          },
        ],
      },
      Id: "4d8ee177-d5f5-4c62-acce-829e775e5e2f",
      Relationships: [
        {
          Type: "CHILD",
          Ids: ["7e4ea175-11ff-4d98-8736-c3c5d47bd62b"],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 89.85363006591797,
      Text: "iRS WITHHELD",
      Geometry: {
        BoundingBox: {
          Width: 0.07088129967451096,
          Height: 0.012461413629353046,
          Left: 0.6497253775596619,
          Top: 0.6538604497909546,
        },
        Polygon: [
          {
            X: 0.6497253775596619,
            Y: 0.6550875902175903,
          },
          {
            X: 0.7204468846321106,
            Y: 0.6538604497909546,
          },
          {
            X: 0.7206066846847534,
            Y: 0.6650947332382202,
          },
          {
            X: 0.6498851776123047,
            Y: 0.666321873664856,
          },
        ],
      },
      Id: "8b3d80ca-f90d-491b-94ef-e528f5e56bd0",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "18530566-a11e-4eaa-a03c-b363c740aef0",
            "bccf6f08-ad30-40e3-b636-862fd17091a9",
          ],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 86.68775177001953,
      Text: "0.00",
      Geometry: {
        BoundingBox: {
          Width: 0.0271961260586977,
          Height: 0.011165070347487926,
          Left: 0.8606744408607483,
          Top: 0.6522683501243591,
        },
        Polygon: [
          {
            X: 0.8606744408607483,
            Y: 0.6527376174926758,
          },
          {
            X: 0.8877184391021729,
            Y: 0.6522683501243591,
          },
          {
            X: 0.8878705501556396,
            Y: 0.6629641652107239,
          },
          {
            X: 0.8608265519142151,
            Y: 0.6634334325790405,
          },
        ],
      },
      Id: "fcae6464-3067-47e3-964f-026d466b0922",
      Relationships: [
        {
          Type: "CHILD",
          Ids: ["c4718e93-e230-4675-8b2b-dbe73d413355"],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 99.8992691040039,
      Text: "INTEREST",
      Geometry: {
        BoundingBox: {
          Width: 0.04735039174556732,
          Height: 0.011706136167049408,
          Left: 0.19905856251716614,
          Top: 0.6710659861564636,
        },
        Polygon: [
          {
            X: 0.19905856251716614,
            Y: 0.6718849539756775,
          },
          {
            X: 0.2462541162967682,
            Y: 0.6710659861564636,
          },
          {
            X: 0.24640895426273346,
            Y: 0.6819531917572021,
          },
          {
            X: 0.1992134153842926,
            Y: 0.682772159576416,
          },
        ],
      },
      Id: "3800b6cf-b102-4be4-a638-d4091c082f4e",
      Relationships: [
        {
          Type: "CHILD",
          Ids: ["e87b8391-4963-40cd-a1f4-d51d74936b76"],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 82.72898864746094,
      Text: "0:00",
      Geometry: {
        BoundingBox: {
          Width: 0.027746951207518578,
          Height: 0.011432991363108158,
          Left: 0.44328799843788147,
          Top: 0.6679171323776245,
        },
        Polygon: [
          {
            X: 0.44328799843788147,
            Y: 0.6683959364891052,
          },
          {
            X: 0.47087913751602173,
            Y: 0.6679171323776245,
          },
          {
            X: 0.4710349440574646,
            Y: 0.6788713932037354,
          },
          {
            X: 0.44344377517700195,
            Y: 0.6793501377105713,
          },
        ],
      },
      Id: "403501f0-5c58-4aca-bc58-7d9386a4e347",
      Relationships: [
        {
          Type: "CHILD",
          Ids: ["138f71bb-9d66-4017-a3cd-3e50df99375c"],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 99.7315673828125,
      Text: "STATE WITHHELD",
      Geometry: {
        BoundingBox: {
          Width: 0.08641133457422256,
          Height: 0.013287900947034359,
          Left: 0.6336933970451355,
          Top: 0.6692924499511719,
        },
        Polygon: [
          {
            X: 0.6336933970451355,
            Y: 0.6707889437675476,
          },
          {
            X: 0.7199370265007019,
            Y: 0.6692924499511719,
          },
          {
            X: 0.7201046943664551,
            Y: 0.6810838580131531,
          },
          {
            X: 0.6338610649108887,
            Y: 0.6825803518295288,
          },
        ],
      },
      Id: "f5690111-d41e-4893-bf47-e8b70ab8fca5",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "b6c9df0e-d237-4c07-b931-17af4eccaeac",
            "9d088b5a-b78e-4c94-88ed-6cc4c3ec84fa",
          ],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 88.48087310791016,
      Text: "0..00",
      Geometry: {
        BoundingBox: {
          Width: 0.027053549885749817,
          Height: 0.011938654817640781,
          Left: 0.8606204390525818,
          Top: 0.6673192977905273,
        },
        Polygon: [
          {
            X: 0.8606204390525818,
            Y: 0.6677858829498291,
          },
          {
            X: 0.8875108361244202,
            Y: 0.6673192977905273,
          },
          {
            X: 0.8876739740371704,
            Y: 0.678791344165802,
          },
          {
            X: 0.860783576965332,
            Y: 0.6792579293251038,
          },
        ],
      },
      Id: "467ba1e2-c551-48b9-8e61-65e742ea42e9",
      Relationships: [
        {
          Type: "CHILD",
          Ids: ["b9649411-8258-4d97-9d37-c66a3ceab092"],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 99.92122650146484,
      Text: "PENALTY",
      Geometry: {
        BoundingBox: {
          Width: 0.0446762777864933,
          Height: 0.01130418200045824,
          Left: 0.19981320202350616,
          Top: 0.6857306957244873,
        },
        Polygon: [
          {
            X: 0.19981320202350616,
            Y: 0.6865032911300659,
          },
          {
            X: 0.2443397045135498,
            Y: 0.6857306957244873,
          },
          {
            X: 0.24448949098587036,
            Y: 0.6962622404098511,
          },
          {
            X: 0.19996298849582672,
            Y: 0.6970348954200745,
          },
        ],
      },
      Id: "0bc1eb75-d484-4b65-be37-e2e6d4df9558",
      Relationships: [
        {
          Type: "CHILD",
          Ids: ["d75c7d48-d28b-4276-9b27-3f9ce89e31d4"],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 99.60121154785156,
      Text: "0.00",
      Geometry: {
        BoundingBox: {
          Width: 0.026588473469018936,
          Height: 0.01115573849529028,
          Left: 0.4438771903514862,
          Top: 0.6847051978111267,
        },
        Polygon: [
          {
            X: 0.4438771903514862,
            Y: 0.6851639151573181,
          },
          {
            X: 0.47031351923942566,
            Y: 0.6847051978111267,
          },
          {
            X: 0.47046566009521484,
            Y: 0.695402204990387,
          },
          {
            X: 0.4440293312072754,
            Y: 0.6958609223365784,
          },
        ],
      },
      Id: "53327ff1-35c0-43c3-81e2-ffe49323a6a7",
      Relationships: [
        {
          Type: "CHILD",
          Ids: ["e93afa3c-3c19-4453-b837-e2d1c075cc82"],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 99.75008392333984,
      Text: "AMOUNT PREVIOUSLY OVERPAID",
      Geometry: {
        BoundingBox: {
          Width: 0.16307708621025085,
          Height: 0.015873655676841736,
          Left: 0.5587995648384094,
          Top: 0.683428168296814,
        },
        Polygon: [
          {
            X: 0.5587995648384094,
            Y: 0.686254620552063,
          },
          {
            X: 0.7216910719871521,
            Y: 0.683428168296814,
          },
          {
            X: 0.7218766808509827,
            Y: 0.6964753270149231,
          },
          {
            X: 0.5589851140975952,
            Y: 0.6993017792701721,
          },
        ],
      },
      Id: "26381e32-03a5-4b87-9ff2-cb17f422b363",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "3345e9d6-f2a7-4910-8ed0-fc68017bf61c",
            "47143da8-46ce-4dc9-860f-5c204945643b",
            "9d3d4453-8e7b-4472-8f8c-07166f18b76b",
          ],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 94.93634033203125,
      Text: "LEVYIGARNISHMENT",
      Geometry: {
        BoundingBox: {
          Width: 0.09669876843690872,
          Height: 0.01317264512181282,
          Left: 0.15120233595371246,
          Top: 0.7002807855606079,
        },
        Polygon: [
          {
            X: 0.15120233595371246,
            Y: 0.7019558548927307,
          },
          {
            X: 0.2477375864982605,
            Y: 0.7002807855606079,
          },
          {
            X: 0.2479010969400406,
            Y: 0.7117783427238464,
          },
          {
            X: 0.15136586129665375,
            Y: 0.7134534120559692,
          },
        ],
      },
      Id: "17ecee2a-a518-45f7-aa71-5ac634406e89",
      Relationships: [
        {
          Type: "CHILD",
          Ids: ["c20796a0-b592-48c4-a112-6a17f15e0147"],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 87.48028564453125,
      Text: "0.00",
      Geometry: {
        BoundingBox: {
          Width: 0.027588976547122,
          Height: 0.011561552993953228,
          Left: 0.8607130646705627,
          Top: 0.6840540766716003,
        },
        Polygon: [
          {
            X: 0.8607130646705627,
            Y: 0.6845300197601318,
          },
          {
            X: 0.8881444334983826,
            Y: 0.6840540766716003,
          },
          {
            X: 0.8883020877838135,
            Y: 0.6951396465301514,
          },
          {
            X: 0.8608707785606384,
            Y: 0.6956155896186829,
          },
        ],
      },
      Id: "51d3213d-7509-49e0-938a-b2a45b61e91e",
      Relationships: [
        {
          Type: "CHILD",
          Ids: ["275e54ca-b7de-4adf-9819-382825867167"],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 99.63589477539062,
      Text: "0.00",
      Geometry: {
        BoundingBox: {
          Width: 0.02767479419708252,
          Height: 0.01137648057192564,
          Left: 0.44345009326934814,
          Top: 0.7005729079246521,
        },
        Polygon: [
          {
            X: 0.44345009326934814,
            Y: 0.7010504007339478,
          },
          {
            X: 0.47096988558769226,
            Y: 0.7005729079246521,
          },
          {
            X: 0.47112488746643066,
            Y: 0.7114718556404114,
          },
          {
            X: 0.44360509514808655,
            Y: 0.711949348449707,
          },
        ],
      },
      Id: "314cfa07-469c-486a-ab6c-46089bf65ad7",
      Relationships: [
        {
          Type: "CHILD",
          Ids: ["a2781d2f-ef81-42d1-bbdb-f3040dfa0d52"],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 99.91734313964844,
      Text: "AMOUNT DISBURSED",
      Geometry: {
        BoundingBox: {
          Width: 0.10769986361265182,
          Height: 0.013426490128040314,
          Left: 0.6119280457496643,
          Top: 0.6998534202575684,
        },
        Polygon: [
          {
            X: 0.6119280457496643,
            Y: 0.701719343662262,
          },
          {
            X: 0.7194634675979614,
            Y: 0.6998534202575684,
          },
          {
            X: 0.7196279168128967,
            Y: 0.7114139199256897,
          },
          {
            X: 0.6120924353599548,
            Y: 0.7132799029350281,
          },
        ],
      },
      Id: "6e61d269-eb46-4214-a8df-92b8b2cb28d2",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "70665e04-e8d4-4aad-89fe-d575cc370a60",
            "2c1733f9-f6b3-4b86-8cc0-e9eabc76af6f",
          ],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 95.01920318603516,
      Text: "NET AMOUNT DUE",
      Geometry: {
        BoundingBox: {
          Width: 0.08642316609621048,
          Height: 0.012915379367768764,
          Left: 0.15692202746868134,
          Top: 0.7162391543388367,
        },
        Polygon: [
          {
            X: 0.15692202746868134,
            Y: 0.7177359461784363,
          },
          {
            X: 0.24318279325962067,
            Y: 0.7162391543388367,
          },
          {
            X: 0.24334518611431122,
            Y: 0.7276577353477478,
          },
          {
            X: 0.1570844203233719,
            Y: 0.7291545271873474,
          },
        ],
      },
      Id: "e625d4c8-e17b-478b-819e-bf59c9e9c02e",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "92f22b57-aa0a-46f0-9dc0-c49b79e4735c",
            "a4b2b108-e89f-41d3-8573-56392667c307",
            "391a68c1-d277-447e-a50c-b5dd007766f3",
          ],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 96.45621490478516,
      Text: "5,711.42",
      Geometry: {
        BoundingBox: {
          Width: 0.05592913180589676,
          Height: 0.012687038630247116,
          Left: 0.8328321576118469,
          Top: 0.700103759765625,
        },
        Polygon: [
          {
            X: 0.8328321576118469,
            Y: 0.7010713815689087,
          },
          {
            X: 0.8885945677757263,
            Y: 0.700103759765625,
          },
          {
            X: 0.8887612819671631,
            Y: 0.7118232250213623,
          },
          {
            X: 0.8329988121986389,
            Y: 0.712790846824646,
          },
        ],
      },
      Id: "1f1cae12-6a0a-46da-bd02-49ec1ea370e1",
      Relationships: [
        {
          Type: "CHILD",
          Ids: ["26d064e0-42de-422a-bb1c-eed653cd6682"],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 89.44656372070312,
      Text: "5,711.42",
      Geometry: {
        BoundingBox: {
          Width: 0.05371815711259842,
          Height: 0.01372787356376648,
          Left: 0.4171229302883148,
          Top: 0.7163234949111938,
        },
        Polygon: [
          {
            X: 0.4171229302883148,
            Y: 0.7172524929046631,
          },
          {
            X: 0.4706590473651886,
            Y: 0.7163234949111938,
          },
          {
            X: 0.47084107995033264,
            Y: 0.7291224598884583,
          },
          {
            X: 0.4173049330711365,
            Y: 0.7300513982772827,
          },
        ],
      },
      Id: "22e543af-198e-4736-87e4-462e356438be",
      Relationships: [
        {
          Type: "CHILD",
          Ids: ["d9383f11-3fc9-4e98-b798-6a87aedf5503"],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 97.49359893798828,
      Text: "RECOUPMENT BALANCE",
      Geometry: {
        BoundingBox: {
          Width: 0.12035739421844482,
          Height: 0.01415221020579338,
          Left: 0.6001863479614258,
          Top: 0.7148735523223877,
        },
        Polygon: [
          {
            X: 0.6001863479614258,
            Y: 0.7169590592384338,
          },
          {
            X: 0.7203721404075623,
            Y: 0.7148735523223877,
          },
          {
            X: 0.7205437421798706,
            Y: 0.7269403338432312,
          },
          {
            X: 0.6003580093383789,
            Y: 0.7290257811546326,
          },
        ],
      },
      Id: "2ca35b1e-d574-41b8-9822-c23792c9bc35",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "eb0fcee3-b04d-4e0f-96bf-72e99ea3c307",
            "8f80067a-5699-4254-9733-981da5567b6a",
          ],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 96.40691375732422,
      Text: "0.00",
      Geometry: {
        BoundingBox: {
          Width: 0.027390804141759872,
          Height: 0.011021635495126247,
          Left: 0.8602944612503052,
          Top: 0.7171478867530823,
        },
        Polygon: [
          {
            X: 0.8602944612503052,
            Y: 0.7176206111907959,
          },
          {
            X: 0.8875352144241333,
            Y: 0.7171478867530823,
          },
          {
            X: 0.887685239315033,
            Y: 0.7276968955993652,
          },
          {
            X: 0.8604444861412048,
            Y: 0.7281695604324341,
          },
        ],
      },
      Id: "237f7047-d273-4ff3-8be4-dba0652a9393",
      Relationships: [
        {
          Type: "CHILD",
          Ids: ["9eb8b60c-d00e-4ccd-83dc-711000cd8918"],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 80.27118682861328,
      Text: "co",
      Geometry: {
        BoundingBox: {
          Width: 0.009716364555060863,
          Height: 0.00848087202757597,
          Left: 0.9778762459754944,
          Top: 0.797585666179657,
        },
        Polygon: [
          {
            X: 0.9778762459754944,
            Y: 0.7977522015571594,
          },
          {
            X: 0.9874743223190308,
            Y: 0.797585666179657,
          },
          {
            X: 0.9875925779342651,
            Y: 0.805899977684021,
          },
          {
            X: 0.9779945015907288,
            Y: 0.8060665130615234,
          },
        ],
      },
      Id: "6f605558-43eb-433c-a70c-4c0d5965a7d0",
      Relationships: [
        {
          Type: "CHILD",
          Ids: ["e7f392b2-7883-467f-a800-17c934ab5ed4"],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 23.610240936279297,
      Text: "O",
      Geometry: {
        BoundingBox: {
          Width: 0.12060794234275818,
          Height: 0.06713719666004181,
          Left: 0.6944135427474976,
          Top: 0.8170257806777954,
        },
        Polygon: [
          {
            X: 0.6944135427474976,
            Y: 0.8191024661064148,
          },
          {
            X: 0.814096212387085,
            Y: 0.8170257806777954,
          },
          {
            X: 0.8150215148925781,
            Y: 0.8820862174034119,
          },
          {
            X: 0.6953388452529907,
            Y: 0.884162962436676,
          },
        ],
      },
      Id: "d9e4dea5-d987-4dcd-a12f-c09741fae76e",
      Relationships: [
        {
          Type: "CHILD",
          Ids: ["1e9a52e8-f661-4c0c-8ebb-3b66ebedd1b4"],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 30.65614128112793,
      Text: "M",
      Geometry: {
        BoundingBox: {
          Width: 0.050805069506168365,
          Height: 0.06038020923733711,
          Left: 0.7233720421791077,
          Top: 0.8176701664924622,
        },
        Polygon: [
          {
            X: 0.7233720421791077,
            Y: 0.8185370564460754,
          },
          {
            X: 0.7733306884765625,
            Y: 0.8176701664924622,
          },
          {
            X: 0.7741771340370178,
            Y: 0.8771834969520569,
          },
          {
            X: 0.7242184281349182,
            Y: 0.8780503869056702,
          },
        ],
      },
      Id: "da886300-02a3-4021-9488-7eb2363aeba5",
      Relationships: [
        {
          Type: "CHILD",
          Ids: ["237b676d-d920-4131-bf23-899ef93ba450"],
        },
      ],
      Page: 3,
    },
    {
      BlockType: "LINE",
      Confidence: 99.60985565185547,
      Text: "DETACH CHECK AT PERFORATION BEFORE DEPOSITING",
      Geometry: {
        BoundingBox: {
          Width: 0.39478248357772827,
          Height: 0.026922665536403656,
          Left: 0.3218860328197479,
          Top: 0.9229550957679749,
        },
        Polygon: [
          {
            X: 0.3218860328197479,
            Y: 0.9298003911972046,
          },
          {
            X: 0.7163829803466797,
            Y: 0.9229550957679749,
          },
          {
            X: 0.7166684865951538,
            Y: 0.943032443523407,
          },
          {
            X: 0.32217156887054443,
            Y: 0.9498777389526367,
          },
        ],
      },
      Id: "588ae089-7016-4613-9029-0abb35a7ed69",
      Relationships: [
        {
          Type: "CHILD",
          Ids: [
            "4cc77f5e-7da2-462c-bf59-3fe37fee7109",
            "a3a15570-015b-4f75-8bdf-7e43ef3b9e62",
            "4b7547fb-9047-400f-b5c9-d237da28e328",
            "0aa28487-8e53-4191-86d6-c0e1815a563f",
            "e1754cda-2ca0-46b8-a116-5dbe17a19e17",
            "7524d551-2129-4b66-9518-81c55735831f",
          ],
        },
      ],
      Page: 3,
    },
  ],
};

export const MAX_WIDTH = 763;
export const MAX_HEIGHT = 590;

export const updateData = (data, org_width, org_height) => {
  let ratio =
    org_width > org_height ? org_width / org_height : org_height / org_width;
  let new_width = MAX_WIDTH;
  let new_height = Math.floor(new_width / ratio);
  const MAX_HEIGHT = new_height;
  for (let block in data["Blocks"]) {
    let width = block["Geometry"]["BoundingBox"]["Width"];
    let height = block["Geometry"]["BoundingBox"]["Height"];
    let left = block["Geometry"]["BoundingBox"]["Left"];
    let top = block["Geometry"]["BoundingBox"]["Top"];
    left = (MAX_WIDTH / 100) * (left * 100);
    top = (MAX_HEIGHT / 100) * (top * 100);
    width = (MAX_WIDTH / 100) * (width * 100);
    height = (MAX_HEIGHT / 100) * ((height + (10 * height) / 100) * 100);
    block["Geometry"]["BoundingBox"]["Width"] = width;
    block["Geometry"]["BoundingBox"]["Height"] = height;
    block["Geometry"]["BoundingBox"]["Left"] = left;
    block["Geometry"]["BoundingBox"]["Top"] = top;
  }
  return [new_width, new_height];
};

export const generateShapes = (data) => {
  let len = data["Blocks"].length;
  return [...Array(len)].map((_, index) => ({
    id: index.toString(),
    x: data["Blocks"][index]["Geometry"]["BoundingBox"]["Left"],
    y: data["Blocks"][index]["Geometry"]["BoundingBox"]["Top"],
    width: data["Blocks"][index]["Geometry"]["BoundingBox"]["Width"],
    height: data["Blocks"][index]["Geometry"]["BoundingBox"]["Height"],
  }));
};

export const svgns = "http://www.w3.org/2000/svg";

export const validateApi = async () => {
  const AUTH = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("accessToken"))}`,
  };
  try {
    let res = await axios.get(`${env.BASE_URL}validatejwttoken`, {
      headers: AUTH,
    });
    if (res?.data === "token valid") return true;
    else return false;
  } catch (err) {
    return false;
  }
};

export const refreshToken = async () => {
  const header = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("refreshToken"))}`,
  };
  try {
    let result = await axios.post(
      `${env.BASE_URL}refreshtoken`,
      {},
      {
        headers: header,
      }
    );
    if (result) {
      localStorage.setItem(
        "accessToken",
        JSON.stringify(result.data.access_token)
      );
      localStorage.setItem(
        "refreshToken",
        JSON.stringify(result.data.refresh_token)
      );
    }
  } catch (err) {
    // .then((res) => {
    //   localStorage.setItem(
    //     "accessToken",
    //     JSON.stringify(res.data.access_token)
    //   );
    //   localStorage.setItem(
    //     "refreshToken",
    //     JSON.stringify(res.data.refresh_token)
    //   );
    // })
    alert("something went wrong,please login again");
    window.location.href = "/";
  }
};

export const valueTypeRegexData = {
  INTEGER: "^\\d+$",
  NUMBER: "^\\d+$",
  FLOAT: "^[$S8*]*-?\\d*(,\\d+)*\\.\\d+$",
  // ALPHABETS: ["[a-zA-Z]+"],
  ALPHABETS:"^[A-Za-z]+$",
  // ALPHABETS: [[a - zA - Z]{ 1, }],
  // ALPHANUMERIC: "[r'\w+']",
  // ALPHANUMERIC: "^[a-zA-Z0-9_]*$",
  ALPHANUMERIC:"(\\d+)+([a-zA-Z]+)+(\\d+)+([a-zA-Z]+)|([a-zA-Z]+)+(\\d+)+([a-zA-Z]+)+(\\d+)|(\\d+)+([a-zA-Z]+)+(\\d+)|([a-zA-Z]+)+(\\d+)+([a-zA-Z]+)|([a-zA-Z]+)+(\\d+)|(\\d+)+([a-zA-Z]+)",
  NUMERIC: "^((\\d+\\.\\d+)|(\\d+))$",
  ANY: "[.+]",
  TEXT: "[.+]",
  AMOUNT: "/^[$S8*]*\d+(,?\d+)*[. ]?\d+$/",
  DATE: [
    /[0-1]?[0-9][/|j\s]{1,2}[0-3]?[0-9][/|j\s]{1,2}\d{3,4}/,
  /[0-3]?[0-9][/|j\s]{1,2}[0-1]?[0-9][/|j\s]{1,2}\d{3,4}/,
  /\d{3,4}[/|j\s]{1,2}[0-1]?[0-9][/|j\s]{1,2}[0-3]?[0-9]/,
  /[0-1]?[0-9][/|j\s]{1,2}[0-3]?[0-9][/|j\s]{1,2}\d{1,2}/,
  /[0-1]?[0-9]M[/|j\s]{1,2}[0-3]?[0-9][/|j\s]{1,2}\d{3,4}/,
  /[0-1]?[0-9]M[/|j\s]{1,2}[0-3]?[0-9][/|j\s]{1,2}\d{1,2}/,
  /[0-3]?[0-9][/|j\s]{1,2}[0-1]?[0-9]M[/|j\s]{1,2}\d{3,4}/,
  /0-3]?[0-9][/|j\s]{1,2}[0-1]?[0-9]M[/|j\s]{1,2}\d{1,2}/,
  /[0-1]?[0-9][/|j\s]{1,2}[0-3]?[0-9][/|j\s]{1,2}\d{3,4}[~\-\s]{1,2}[0-1]?[0-9][/|j\s]{1,2}[0-3]?[0-9][/|j\s]{1,2}\d{3,4}/,
  /[0-1]?[0-9][/|j\s]{1,2}[0-3]?[0-9][/|j\s]{1,2}\d{1,2}/,
  /[0-1]?[0-9][/|j\s]{1,2}[0-3]?[0-9][/|j\s]{1,2}\d{1,2}[~\-\s]{1,2}[0-1]?[0-9][/|j\s]{1,2}[0-3]?[0-9][/|j\s]{1,2}\d{1,2}/,
  /[0-1]?[0-9][/|j\s]{1,2}[0-3]?[0-9][/|j\s]{1,2}\d{3,4}/,
  /[0-1]?[0-9][/|j\s]{1,2}[0-3]?[0-9][~\-\s]{1,2}[0-1]?[0-9][/|j\s]{1,2}[0-3]?[0-9][/|j\s]{1,2}\d{3,4}/,
  /[0-1]?[0-9][/|j\s]{1,2}[0-3]?[0-9][~\-\s]{1,2}[0-1]?[0-9][/|j\s]{1,2}[0-3]?[0-9][/|j\s]{1,2}\d{1,2}/,
  // /[0-1]?[0-9][0-3]?[0-9]\d{3,4}/,
  // /[0-1]?[0-9][0-3]?[0-9]\d{1,2}/,
  /[0-1]?[0-9][0-3]?[0-9]\d{3,4}[~\-\s]{1,2}[0-1]?[0-9][0-3]?[0-9]\d{3,4}/,
  /[0-1]?[0-9][0-3]?[0-9][~\-\s]{1,2}[0-1]?[0-9][0-3]?[0-9]\d{3,4}/,
  /[0-1]?[0-9][0-3]?[0-9]\d{1,2}[~\-\s]{1,2}[0-1]?[0-9][0-3]?[0-9]\d{1,2/,
  /[0-1]?[0-9][0-3]?[0-9][~\-\s]{1,2}[0-1]?[0-9][0-3]?[0-9]\d{1,2}/,
  ],
};

export const dateDictionary = {
  "MM/DD/YYYY": /^[0-1]?[0-9][/|j\s]{1,2}[0-3]?[0-9][/|j\s]{1,2}\d{3,4}$/,
  "MM-DD-YYYY":/^[0-1]?[0-9][-|j\s]{1,2}[0-3]?[0-9][-|j\s]{1,2}\d{3,4}$/,
  "MM-DD-YY":/^[0-1]?[0-9][-|j\s]{1,2}[0-3]?[0-9][-|j\s]{1,2}\d{1,2}$/,
  "DD/MM/YYYY": /^[0-3]?[0-9][/|j\s]{1,2}[0-1]?[0-9][/|j\s]{1,2}\d{3,4}$/,
  "YYYY/MM/DD": /^\d{3,4}[/|j\s]{1,2}[0-1]?[0-9][/|j\s]{1,2}[0-3]?[0-9]$/,
  "MM/DD/YY": /^[0-1]?[0-9][/|j\s]{1,2}[0-3]?[0-9][/|j\s]{1,2}\d{1,2}$/,
  "MMM/DD/YYYY": /^[A-Za-z]+[/|j\s]{1,2}[0-3]?[0-9][/|j\s]{1,2}\d{3,4}$/,
  "MMM/DD/YY": /^[A-Za-z]+[/|j\s]{1,2}[0-3]?[0-9][/|j\s]{1,2}\d{1,2}4$/,
  "DD/MMM/YYYY": /^[0-3]?[0-9][/|j\s]{1,2}[A-Za-z]+[/|j\s]{1,2}\d{3,4}$/,
  "DD/MMM/YY": /^[0-3]?[0-9][/|j\s]{1,2}[A-Za-z]+[/|j\s]{1,2}\d{1,2}$/,
  "MM/DD/YYYY-MM/DD/YYYY": /^[0-1]?[0-9][/|j\s]{1,2}[0-3]?[0-9][/|j\s]{1,2}\d{3,4}[~\-\s]{1,2}[0-1]?[0-9][/|j\s]{1,2}[0-3]?[0-9][/|j\s]{1,2}\d{3,4}/,
  "MM-DD-YYYY-MM-DD-YYYY":/^[0-1]?[0-9][-|j\s]{1,2}[0-3]?[0-9][-|j\s]{1,2}\d{3,4}[~\-\s]{1,2}[0-1]?[0-9][-|j\s]{1,2}[0-3]?[0-9][-|j\s]{1,2}\d{3,4}/,
  "MM/DD/YY-MM/DD/YY": /^[0-1]?[0-9][/|j\s]{1,2}[0-3]?[0-9][/|j\s]{1,2}\d{1,2}[~\-\s]{1,2}[0-1]?[0-9][/|j\s]{1,2}[0-3]?[0-9][/|j\s]{1,2}\d{1,2}/,
  "MM/DD/YYYY MM/DD/YYYY": /^[0-1]?[0-9][/|j\s]{1,2}[0-3]?[0-9][/|j\s]{1,2}\d{3,4}/,
  "MM/DD-MM/DD/YYYY": /^[0-1]?[0-9][/|j\s]{1,2}[0-3]?[0-9][~\-\s]{1,2}[0-1]?[0-9][/|j\s]{1,2}[0-3]?[0-9][/|j\s]{1,2}\d{3,4}/,
  "MM/DD-MM/DD/YY": /^[0-1]?[0-9][/|j\s]{1,2}[0-3]?[0-9][~\-\s]{1,2}[0-1]?[0-9][/|j\s]{1,2}[0-3]?[0-9][/|j\s]{1,2}\d{1,2}/,  
  "MMDDYYYY": /([0-1]{1}?[0-9]{1})+([0-3]{1}?[0-9]{1})+[0-9]\d{3,4}/,
  "MMDDYY": /^[0-1]?[0-9][0-3]?[0-9]\d{1,2}$/,
  "MMDDYYYY-MMDDYYYY": /^[0-1]?[0-9][0-3]?[0-9]\d{3,4}[~\-\s]{1,2}[0-1]?[0-9][0-3]?[0-9]\d{3,4}/,
  "MMDD-MMDDYYYY": /^[0-1]?[0-9][0-3]?[0-9][~\-\s]{1,2}[0-1]?[0-9][0-3]?[0-9]\d{3,4}/,
  "MMDDYY-MMDDYY": /^[0-1]?[0-9][0-3]?[0-9]\d{1,2}[~\-\s]{1,2}[0-1]?[0-9][0-3]?[0-9]\d{1,2}/,
  "MMDD-MMDDYY": /^[0-1]?[0-9][0-3]?[0-9][~\-\s]{1,2}[0-1]?[0-9][0-3]?[0-9]\d{1,2}/,
  "YYYY-MM-DD": /^\d{3,4}[~\-\s]{1,2}[0-1]?[0-9][~\-\s]{1,2}[0-3]?[0-9]/,
  "MM/DD/YY - MM/DD/YY": /^[0-1]?[0-9][/|j\s]{1,2}[0-3]?[0-9][/|j\s]{1,2}\d{1,2}[~\-\s]{1,4}[0-1]?[0-9][/|j\s]{1,2}[0-3]?[0-9][/|j\s]{1,2}\d{1,2}/,
  "MM-DD-YY - MM-DD-YY": /^[0-1]?[0-9][~\-\s]{1,2}[0-3]?[0-9][~\-\s]{1,2}\d{1,2}[~\-\s]{1,4}[0-1]?[0-9][~\-\s]{1,2}[0-3]?[0-9][~\-\s]{1,2}\d{1,2}/,
  "MM-DD-YY-MM-DD-YY": /^[0-1]?[0-9][~\-\s]{1,2}[0-3]?[0-9][~\-\s]{1,2}\d{1,2}[~\-\s]{1,2}[0-1]?[0-9][~\-\s]{1,2}[0-3]?[0-9][~\-\s]{1,2}\d{1,2}/,
  "MM-DD-YYYY - MM-DD-YYYY": /^[0-1]?[0-9][~\-\s]{1,2}[0-3]?[0-9][~\-\s]{1,2}\d{3,4}[~\-\s]{1,4}[0-1]?[0-9][~\-\s]{1,2}[0-3]?[0-9][~\-\s]{1,2}\d{3,4}/,
  "MM/DD/YYYY - MM/DD/YYYY": /^[0-1]?[0-9][/|j\s]{1,2}[0-3]?[0-9][/|j\s]{1,2}\d{3,4}[~\-\s]{1,4}[0-1]?[0-9][/|j\s]{1,2}[0-3]?[0-9][/|j\s]{1,2}\d{3,4}/,
}