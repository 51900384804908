/* eslint-disable no-unused-vars */
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import Routes from "./routes";

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
};

const App = () => (
  <ErrorBoundary
    FallbackComponent={ErrorFallback}
    onReset={() => {
      // reset the state of your app so the error doesn't happen again
      console.log("reset");
    }}
  >
    <Routes />
  </ErrorBoundary>
);

export default App;
