import Loader from 'react-loader-spinner';
import React from 'react';

function loader(props) {
  return (
    <>
      <div style={{
        position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
      }}
      >
        <Loader
          type="Bars"
          color="rgb(51, 83, 109)"
          height={100}
          width={85}
        />
      </div>
      <center><p className="mt-6">Please wait, This might take sometime !<br /><br /><b>DO NOT REFRESH </b></p></center>

    </>
  );
}

export default loader;