/* eslint-disable  */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prefer-stateless-function */
import React, { useState } from "react";
import { Navbar, Button, Nav, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import Logo from "../../assets/images/rcm.png";

import "./Navbar.css";

const signOut = () => {
  localStorage.clear();
  window.location.href = "/";
};

const NavBar = (props) => {
  const dispatch = useDispatch();
  const templateID = useSelector(({ save }) => save.templateID);
  const name = useSelector(({ save }) => save.name);
  const loading = useSelector(({ save }) => save.loadingHome);

  const home = () => props.history.push("/home");

  return (
    <div>
      <Navbar className="Nav">
        <Navbar.Brand
          className="grab"
          style={{ width: "25%", marginLeft: 30, cursor: 'pointer' }}
          onClick={() => {
            props.history.push("/home");
          }}
        >
          <img
            alt=""
            src={Logo}
            width="39"
            height="39"
            className="d-inline-block align-top logoNav"
          />
        </Navbar.Brand>
        <Nav.Item className="ml-auto" style={{ width: "45%" }}>
          <center>
            <h3 style={{ color: "rgb(51, 83, 109)" }}>Config Management</h3>
          </center>
        </Nav.Item>
        <Nav.Item className="ml-auto" style={{ width: "30%" }}>
          <Button
            className="SignOut"
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              backgroundColor: "rgb(51, 83, 109)",
              border: "none",
              float: "right",
              margin: "3px",
            }}
            onClick={() => signOut()}
          >
            Sign Out&nbsp;
            <i
              className="fa sign fa-sign-out sign"
              aria-hidden="true"
              style={{ padding: "5px", fontSize: "20px" }}
            />
          </Button>
          <Button
            className="SignOut"
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              backgroundColor: "rgb(51, 83, 109)",
              float: "right",
              border: "none",
              margin: "3px",
            }}
            onClick={() => {
              window.open(
                "https://sway.office.com/nzqe2HQqc9Kaf01z?play",
                "_blank"
              );
            }}
          >
            Help&nbsp;
            <i
              className="fa fa-info-circle"
              aria-hidden="true"
              style={{
                padding: "5px",
                float: "right",
                fontSize: "20px",
                fontWeight: "bold",
              }}
            />
          </Button>
          {props.component === "home" ? null : (
            <Button
              disabled={loading}
              className="Home"
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                backgroundColor: "rgb(51, 83, 109)",
                border: "none",
                float: "right",
                margin: "3px",
              }}
              onClick={home}
            >
              {loading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                `Home`
              )}
              <i
                className="fa fa-home"
                aria-hidden="true"
                style={{ padding: "5px", fontSize: "20px" }}
              />
            </Button>
          )}
        </Nav.Item>
      </Navbar>
    </div>
  );
};

export default withRouter(NavBar);
