export const FORM = "FORM";
export const RELOADED = "RELOADED";
export const ABOVE = "ABOVE";
export const SAVE_DROPDOWN = "SAVE_DROPDOWN";
export const DELETE_FORM_ITEM = "DELETE_FORM_ITEM";
export const ERROR_IN_DROPDOWN = "ERROR_IN_DROPDOWN";
export const BELOW = "BELOW";
export const TEXT = "TEXT";
export const RMEOVE_TEXT = "RMEOVE_TEXT";
export const TEMPLATE_ACTIVE = "TEMPLATE_ACTIVE";
export const CLAIM_HEADER_IDEN_ACTIVE = "CLAIM_HEADER_IDEN_ACTIVE";

export const NEGATE_ACTIVE = "NEGATE_ACTIVE";
export const INDETIFICATION_ACTIVE = "INDETIFICATION_ACTIVE";
export const TEMPLATE_TEXT = "TEMPLATE_TEXT";
export const CLAIM_HEADER_IDEN_TEXT = "CLAIM_HEADER_IDEN_TEXT";

export const NEGATE_TEXT = "NEGATE_TEXT";
export const INDETIFICATION_TEXT = "INDETIFICATION_TEXT";
export const CEHCK_ADDRESS_ACTIVE = "CEHCK_ADDRESS_ACTIVE";
export const CARD_ADDRESS_ACTIVE = "CARD_ADDRESS_ACTIVE";

export const CEHCK_ADDRESS_OPEN = "CEHCK_ADDRESS_OPEN";
export const CARD_ADDRESS_OPEN = "CARD_ADDRESS_OPEN";
export const DELETE_CHECK_ADD_DATA = "DELETE_CHECK_ADD_DATA";
export const DISABLE_CHECK_ADD_ACTIVE = "DISABLE_CHECK_ADD_ACTIVE";
export const DELETE_CLAIM_ADD_DATA = "DELETE_CLAIM_ADD_DATA";
export const DISABLE_CLAIM_ADD_ACTIVE = "DISABLE_CLAIM_ADD_ACTIVE";
export const DELETE_CHECK_HEAD_DATA = "DELETE_CHECK_HEAD_DATA";
export const DISABLE_CHECK_HEAD_ACTIVE = "DISABLE_CHECK_HEAD_ACTIVE";
export const DELETE_CLAIM_HEAD_DATA = "DELETE_CLAIM_HEAD_DATA";
export const DISABLE_CLAIM_HEAD_ACTIVE = "DISABLE_CLAIM_HEAD_ACTIVE";
export const DELETE_CLAIM_FIELDS_DATA = "DELETE_CLAIM_FIELDS_DATA";
export const DISABLE_CLAIM_FIELDS_ACTIVE = "DISABLE_CLAIM_FIELDS_ACTIVE";
export const DISABLE_CARD_ADDRESS_ACTIVE = "DISABLE_CARD_ADDRESS_ACTIVE";
export const DISABLE_CARD_HEADER_ACTIVE = "DISABLE_CARD_HEADER_ACTIVE";
export const DISABLE_CLAIM_EXTRACTION_ACTIVE =
  "DISABLE_CLAIM_EXTRACTION_ACTIVE";
export const DISABLE_LINE_ITEM_ACTIVE = "DISABLE_LINE_ITEM_ACTIVE";
export const DISABLE_TEMPLATE_PAGE_IDENTIFIER_ACTIVE =
  "DISABLE_TEMPLATE_PAGE_IDENTIFIER_ACTIVE";

// template open states
export const NEGATE_OPEN = "NEGATE_OPEN";
export const TEMPLATE_OPEN = "TEMPLATE_OPEN";
export const IDENTIFICATION_OPEN = "IDENTIFICATION_OPEN";

export const NEGATE_OPEN1 = "NEGATE_OPEN1";
export const TEMPLATE_OPEN1 = "TEMPLATE_OPEN1";
export const IDENTIFICATION_OPEN1 = "IDENTIFICATION_OPEN1";

export const CEHCK_ADDRESS_TEXT = "CEHCK_ADDRESS_TEXT";
export const CARD_ADDRESS_TEXT = "CARD_ADDRESS_TEXT";

export const CEHCK_ADDRESS_ACTIVE_2 = "CEHCK_ADDRESS_ACTIVE_2";
export const CEHCK_ADDRESS_ACTIVE_3 = "CEHCK_ADDRESS_ACTIVE_3";
export const CHECK_ADDRESS = "CHECK_ADDRESS";
export const CHECK_ADDRESS_2 = "CHECK_ADDRESS_2";
export const CHECK_ADDRESS_3 = "CHECK_ADDRESS_3";
export const CHECK_HEADER_ACTIVE = "CHECK_HEADER_ACTIVE";
export const CARD_HEADER_ACTIVE = "CARD_HEADER_ACTIVE";

export const CHECK_HEADER_ACTIVE_IDENTIFIER1 =
  "CHECK_HEADER_ACTIVE_IDENTIFIER1";
export const CHECK_HEADER_ACTIVE_IDENTIFIER2 =
  "CHECK_HEADER_ACTIVE_IDENTIFIER2";
export const CHECK_HEADER_ACTIVE_IDENTIFIER3 =
  "CHECK_HEADER_ACTIVE_IDENTIFIER3";
export const CHECK_HEADER_ACTIVE_IDENTIFIER4 =
  "CHECK_HEADER_ACTIVE_IDENTIFIER4";
export const CHECK_HEADER_ACTIVE_IDENTIFIER5 =
  "CHECK_HEADER_ACTIVE_IDENTIFIER5";
export const CHECK_HEADER_ACTIVE_IDENTIFIER6 =
  "CHECK_HEADER_ACTIVE_IDENTIFIER6";
export const CHECK_HEADER_ACTIVE_IDENTIFIER7 =
  "CHECK_HEADER_ACTIVE_IDENTIFIER7";
export const CHECK_HEADER_ACTIVE_IDENTIFIER8 =
  "CHECK_HEADER_ACTIVE_IDENTIFIER8";
export const CHECK_HEADER_ACTIVE_IDENTIFIER9 =
  "CHECK_HEADER_ACTIVE_IDENTIFIER9";
export const CHECK_HEADER_ACTIVE_IDENTIFIER10 =
  "CHECK_HEADER_ACTIVE_IDENTIFIER10";
export const CHECK_HEADER_ACTIVE_IDENTIFIER11 =
  "CHECK_HEADER_ACTIVE_IDENTIFIER11";
export const CHECK_HEADER_ACTIVE_IDENTIFIER12 =
  "CHECK_HEADER_ACTIVE_IDENTIFIER12";
export const CHECK_HEADER_ACTIVE_IDENTIFIER13 =
  "CHECK_HEADER_ACTIVE_IDENTIFIER13";
export const CHECK_HEADER_ACTIVE_IDENTIFIER14 =
  "CHECK_HEADER_ACTIVE_IDENTIFIER14";
export const CHECK_HEADER_TEXT = "CHECK_HEADER_TEXT";
export const CARD_HEADER_TEXT = "CARD_HEADER_TEXT";

export const CHECK_HEADER_ACTIVE_END_IDENTIFIER1 =
  "CHECK_HEADER_ACTIVE_END_IDENTIFIER1";

export const CHECK_HEADER_ACTIVE_END_IDENTIFIER2 =
  "CHECK_HEADER_ACTIVE_END_IDENTIFIER2";
export const CHECK_HEADER_ACTIVE_END_IDENTIFIER3 =
  "CHECK_HEADER_ACTIVE_END_IDENTIFIER3";
export const CHECK_HEADER_ACTIVE_END_IDENTIFIER4 =
  "CHECK_HEADER_ACTIVE_END_IDENTIFIER4";
export const CHECK_HEADER_ACTIVE_END_IDENTIFIER5 =
  "CHECK_HEADER_ACTIVE_END_IDENTIFIER5";
export const CHECK_HEADER_ACTIVE_END_IDENTIFIER6 =
  "CHECK_HEADER_ACTIVE_END_IDENTIFIER6";
export const CHECK_HEADER_ACTIVE_END_IDENTIFIER7 =
  "CHECK_HEADER_ACTIVE_END_IDENTIFIER7";
export const CHECK_HEADER_ACTIVE_END_IDENTIFIER8 =
  "CHECK_HEADER_ACTIVE_END_IDENTIFIER8";
export const CHECK_HEADER_ACTIVE_END_IDENTIFIER9 =
  "CHECK_HEADER_ACTIVE_END_IDENTIFIER9";
export const CHECK_HEADER_ACTIVE_END_IDENTIFIER10 =
  "CHECK_HEADER_ACTIVE_END_IDENTIFIER10";
export const CHECK_HEADER_ACTIVE_END_IDENTIFIER11 =
  "CHECK_HEADER_ACTIVE_END_IDENTIFIER11";
export const CHECK_HEADER_ACTIVE_END_IDENTIFIER12 =
  "CHECK_HEADER_ACTIVE_END_IDENTIFIER12";
export const CHECK_HEADER_ACTIVE_END_IDENTIFIER13 =
  "CHECK_HEADER_ACTIVE_END_IDENTIFIER13";

export const TOP_IDENTIFIER_ACTIVE = "TOP_IDENTIFIER_ACTIVE";

export const BOTTOM_IDENTIFIER_ACTIVE = "BOTTOM_IDENTIFIER_ACTIVE";
export const HEADER_IDENTIFIER_ACTIVE = "HEADER_IDENTIFIER_ACTIVE";
export const FOOTER_IDENTIFIER_ACTIVE = "FOOTER_IDENTIFIER_ACTIVE";
export const ACTIVE_STATE = "ACTIVE_STATE";

// claims
export const SAVE_CLAIM_DATA = "SAVE_CLAIM_DATA";

export const CLAIM_EXTRACTION_ACTIVE = "CLAIM_EXTRACTION_ACTIVE";
export const CLAIM_EXTRACTION_TEXT = "CLAIM_EXTRACTION_TEXT";

export const CLAIM_ADDRESS_ACTIVE = "CLAIM_ADDRESS_ACTIVE";
export const CLAIM_ADDRESS_TEXT = "CLAIM_ADDRESS_TEXT";

export const CLAIM_HEADER_ACTIVE = "CLAIM_HEADER_ACTIVE";
export const CLAIM_HEADER_TEXT = "CLAIM_HEADER_TEXT";

export const CLAIM_FIELDS_ACTIVE = "CLAIM_FIELDS_ACTIVE";
export const CLAIM_FIELDS_TEXT = "CLAIM_FIELDS_TEXT";

// line

export const LINE_ITEM_PROPERTIES_ACTIVE = "LINE_ITEM_PROPERTIES_ACTIVE";
export const LINE_ITEM_PROPERTIES_TEXT = "LINE_ITEM_PROPERTIES_TEXT";

export const LINE_ITEM_FIELD_PROPERTIES_ACTIVE =
  "LINE_ITEM_FIELD_PROPERTIES_ACTIVE";
export const LINE_ITEM_FIELD_PROPERTIES_TEXT =
  "LINE_ITEM_FIELD_PROPERTIES_TEXT";

export const SAVE_CREDS = "SAVE_CREDS";
export const DISABLE_ACTIVE = "DISABLE_ACTIVE";
export const DISABLE_ACTIVE1 = "DISABLE_ACTIVE1";
export const DELETE_DATA = "DELETE_DATA";
export const CURRENTPDF = "CURRENTPDF";
