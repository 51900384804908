/* eslint-disable */

import React, { useEffect, useRef } from "react";
import $ from "jquery";
import { MAX_WIDTH, MAX_HEIGHT, svgns } from "./constants";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

// import Img1 from "./assets/images/img1.png";
import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import { imageText, tempBlock2, checkImg } from "./store/Form/actions";

const Image = ({ top = 0, left = 0, width, data, src, pageNo, fileN }) => {
  const dispatch = useDispatch();
  const state = useSelector(({ form }) => form);
  const imageRef = useRef();
  const maskRef = useRef();
  const svgRef = useRef();

  let Data = JSON.parse(JSON.stringify(data));
  if (state.form === "line") {
    Data["Blocks"] = Data?.Blocks.filter((block) => block?.BlockType == "WORD");
  } else {
    Data["Blocks"] = Data?.Blocks.filter((block) => block?.BlockType == "LINE");
  }
  let actual_data = JSON.parse(JSON.stringify(Data));
  const [value, setValue] = React.useState("");
  const [activeState, setActiveState] = React.useState("");
  let val = "";
  let prevVal = "";
  let ActiveState = "";
  let MaxTop = 0;

  useEffect(() => {
    if (val) {
      setValue(val);
      window.location.reload();
    }
    if (state) {
      //clear prev val
      val = "";
    }
  }, [val, state]);

  React.useEffect(() => {
    handleLoad();
  }, [state.form]);

  React.useEffect(() => {
    if (activeState) {
      ActiveState = activeState;
    }
  }, [activeState]);

  const updateData = (org_width, org_height) => {
    let ratio = 0,
      new_width = 0,
      new_height = 0;
    new_width = MAX_WIDTH;

    if (org_width > org_height) {
      ratio = org_width / org_height;
      new_height = Math.floor(new_width / ratio);
    } else {
      ratio = org_height / org_width;
      new_height = Math.floor(new_width * ratio);
    }
    //    if (org_width > org_height) {
    //      ratio = org_width / org_height;
    //      new_width = MAX_WIDTH;
    //      new_height = Math.floor(new_width / ratio);
    //      while (new_height > MAX_HEIGHT) {
    //        new_width -= 1;
    //        new_height = Math.floor(new_width / ratio);
    //      }
    //    } else {
    //      ratio = org_height / org_width;
    //      new_height = MAX_HEIGHT;
    //      new_width = Math.floor(new_height / ratio);
    //      while (new_width > MAX_WIDTH) {
    //        new_height -= 1;
    //        new_width = Math.floor(new_height / ratio);
    //      }
    //    }
    //    const newData = Data;
    const blocks = Data?.Blocks;
    if (blocks.length && blocks) {
      blocks.map(
        (
          {
            Geometry: {
              BoundingBox: { Width, Height, Left, Top },
            },
          },
          i
        ) => {
          let width = Width;
          let height = Height;
          let left = Left;
          let top = Top;
          left = (new_width / 100) * (left * 100);
          top = (new_height / 100) * (top * 100);
          width = (new_width / 100) * (width * 100);
          height = (new_height / 100) * ((height + (10 * height) / 100) * 100);
          blocks[i].Geometry.BoundingBox.Width = width;
          blocks[i].Geometry.BoundingBox.Height = height;
          blocks[i].Geometry.BoundingBox.Left = left;
          blocks[i].Geometry.BoundingBox.Top = top;
        }
      );
    }
    return [new_width, new_height];

  };

  const generateShapes = () => {
    let len = Data["Blocks"].length;
    return [...Array(len)].map((_, index) => ({
      id: index.toString(),
      x: Data["Blocks"][index]["Geometry"]["BoundingBox"]["Left"],
      y: Data["Blocks"][index]["Geometry"]["BoundingBox"]["Top"],
      width: Data["Blocks"][index]["Geometry"]["BoundingBox"]["Width"],
      height: Data["Blocks"][index]["Geometry"]["BoundingBox"]["Height"],
    }));
  };
  const handleLoad = () => {
    if (
      imageRef?.current?.naturalWidth != 0 &&
      imageRef?.current?.naturalHeight != 0
    ) {
      //    setUpdatedData(
      let updatedData = updateData(
        imageRef?.current?.naturalWidth,
        imageRef?.current?.naturalHeight
      );
      //    );
      // debugger
      let top = 0,
        left = 0;
      top = Math.floor((MAX_HEIGHT - updatedData[1]) / 2);
      top = top < 0 ? 0 : top;
      //      left = Math.floor((MAX_WIDTH - updatedData[0]) / 2);

      if (updatedData.length) {
        $(`#${svgRef.current.id}`).removeAttr("viewBox");
        $(`#${svgRef.current.id}`).each(function () {
          $(this)[0].setAttribute("width", `${updatedData[0]}`);
          $(this)[0].setAttribute("height", `${updatedData[1]}`);
          $(this)[0].setAttribute("style", `position:absolute;`);
        });
        if (top > 0) {
          $(".main").attr("style", "height: 590px");
        } else {
          $(".main").attr("style", "height: 590px;overflow:auto");
        }
        $("#imageId").attr(
          "style",
          `position:relative;width: ${updatedData[0]}px;height: ${updatedData[1]}px;align:center`
        );
        let wrapper_tag = $(".transform-component-module_wrapper__1_Fgj");
        if (wrapper_tag) {
          wrapper_tag.attr(
            "style",
            `top:${top}px;left:${left}px;position:relative`
          );
        }
        $("#transformWrapper").attr("initialPositionX", left);
        $("#transformWrapper").attr("initialPositionY", top);
      }

      const modified_data = generateShapes(Data);
      // console.log("modified_data", modified_data);
      let fileClass = fileN.replace(/[^a-zA-Z0-9]/g,'');
      let fileClassName = `\\${fileClass}-${pageNo}`;
      let remove_path_tags = document.querySelectorAll(
        `path:not(.${fileClassName}`
      );
      if (remove_path_tags) {
        for (let i = 0; i < remove_path_tags.length; i++)
          remove_path_tags[i].remove();
      }

      modified_data.map(({ id, height, width, y, x }) => {
        // console.log("x,y", x, y);
        let path_tag = document.createElementNS(svgns, "path");

        path_tag.setAttribute("id", id);
        path_tag.setAttribute("check", id);
        path_tag.setAttribute("fill", "none");
        path_tag.setAttribute("stroke", "green");
        path_tag.setAttribute("click", "this");
        path_tag.setAttribute("class", fileClassName);
        path_tag.setAttribute(
          "d",
          `M${x} ${y} L${x + width} ${y} L${x + width} ${y + height} L${x} ${
            y + height
          } Z`
        );
        path_tag.addEventListener("click", function (e) {
          // console.log("e", e);

          // console.log(Data?.Blocks[Number(e.target.id)]);
          var clickVal = JSON.parse(JSON.stringify(actual_data?.Blocks[Number(e.target.id)]));
          // console.log("val in effect", clickVal);
          if (e.ctrlKey) {
            if (val == "") {
              MaxTop = 0;
              val = clickVal.Text;
              prevVal = clickVal;
            } else {
              val = val + " " + clickVal.Text;
              let prev_bounding_box = prevVal['Geometry']['BoundingBox'];
              let current_bounding_box = {...clickVal['Geometry']['BoundingBox']};
              let final_bounding_box = {};
              if (Math.abs(prev_bounding_box['Top'] - current_bounding_box['Top']) > 0.005){
                    final_bounding_box['Left'] = current_bounding_box["Left"] >= prev_bounding_box['Left'] ? prev_bounding_box['Left'] : current_bounding_box['Left'];
                    final_bounding_box['Top'] = current_bounding_box["Top"] >= prev_bounding_box['Top'] ? prev_bounding_box['Top'] : current_bounding_box['Top'];
                    final_bounding_box['Width'] = current_bounding_box["Width"]  <= prev_bounding_box['Width'] ? prev_bounding_box['Width'] : current_bounding_box["Width"];
                    if (MaxTop == 0){
                        MaxTop =  current_bounding_box["Top"] + current_bounding_box["Height"]  <= prev_bounding_box['Top'] + prev_bounding_box['Height'] ? prev_bounding_box['Top'] + prev_bounding_box['Height'] : current_bounding_box["Top"] + current_bounding_box["Height"];
                    }
                    else if(current_bounding_box["Height"] + current_bounding_box['Top'] > MaxTop){
                        MaxTop = current_bounding_box["Top"] + current_bounding_box["Height"];
                    }
                    final_bounding_box['Height'] = MaxTop;
                }
                else{
                    final_bounding_box['Width'] = current_bounding_box["Left"] + current_bounding_box["Width"]  - prev_bounding_box['Left'];
                    final_bounding_box['Left'] = prev_bounding_box['Left'];
                    final_bounding_box['Top'] = prev_bounding_box['Top'];
                    final_bounding_box['Height'] = prev_bounding_box['Height'];
                }
                prevVal['Geometry']['BoundingBox'] = {...final_bounding_box};
            }

            setValue(val);
            const { BlockType, Confidence, Text, Geometry, Page, Id } = prevVal;
            const { BoundingBox } = Geometry;

            const imgData = {
              data: {
                BlockType,
                Confidence,
                Text,
                BoundingBox,
                pageNumber: Page,
                Id,
                fileName: fileN,
              },
            };

            dispatch(
              imageText({
                text: val,
                data: {
                  BlockType,
                  Confidence,
                  Text,
                  BoundingBox,
                  pageNumber: Page,

                  fileName: fileN,
                },
              })
            );
            // dispatch(imageText({ text: val, data: clickVal }));
          } else {
            MaxTop = 0;
            val = clickVal.Text;
            prevVal = clickVal;
            setValue(val);

            const { BlockType, Confidence, Text, Geometry, Page } = clickVal;
            const { BoundingBox } = Geometry;

            dispatch(
              imageText({
                text: val,
                data: {
                  BlockType,
                  Confidence,
                  Text,
                  BoundingBox,
                  pageNumber: Page,

                  fileName: fileN,
                },
              })
            );
          }
        });
        $(`#${svgRef.current.id}`).append(path_tag);

        let path_tag2 = document.createElementNS(svgns, "path");
        path_tag2.setAttribute(
          "d",
          `M${x} ${y}
            L${x + width} ${y}
            L${x + width} ${y + height}
            L${x} ${y + height} Z`
        );
        path_tag2.setAttribute("class", fileClassName);
        $(`#${maskRef.current.id}`).append(path_tag2);
      });
    }
  };

  return (
    <div className="main" style={{ overflow: "auto", height: "590px" }}>
      {/* eslint-disable-next-line */}
      <TransformWrapper
        id="transformWrapper"
        initialScale={1}
        wheel={{ disabled: true }}
      >
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <React.Fragment>
            <div className="tools">
              <button onClick={() => zoomIn()}>+</button>
              <button onClick={() => zoomOut()}>-</button>
              <button onClick={() => resetTransform()}>x</button>
            </div>
            <TransformComponent id="transformComponent">
              <img
                id={"imageId"}
                alt="image"
                src={src}
                style={{
                  height: "initial",
                  position: "absolute",
                  top,
                  left,
                  width: width ? width : "-webkit-fill-available",
                }}
                ref={imageRef}
                onLoad={handleLoad}
              />
              <svg
                alt={"svg"}
                ref={svgRef}
                id="box-render-svg"
                //        style={{ position: "absolute", top, width, left }}
              >
                <defs>
                  <style
                    dangerouslySetInnerHTML={{
                      __html: `
                  path {
                    stroke: #54545E;
                       padding: 100px;
                  }
                  path:active{
                   stroke: green;
                  }
                `,
                    }}
                  />
                </defs>
                <mask id="darkhead" ref={maskRef}>
                  <rect width="100%" height="100%" fill="white"></rect>
                </mask>
              </svg>
            </TransformComponent>
          </React.Fragment>
        )}
      </TransformWrapper>
    </div>
  );
};

export default Image;
